import { usePersistedDataStore, useLocalDataStore } from '../components/store';
import { useState, useCallback, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';

export function useStreamProcessorNew(setFetchIterationCallback) {
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const setEvalDate = usePersistedDataStore((state) => state.setEvalDate);
  const setRar = usePersistedDataStore((state) => state.setRar);
  const setEq = usePersistedDataStore((state) => state.setEq);
  // const setReviewPage = usePersistedDataStore((state) => state.setReviewPage);

  // These two objects - if you can remove I think would be a good optimization.
  // these update with relative freuqency and cause you to need to recreate this function
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const [loading, setLoading] = useState(false);

  const iterationRef = useRef(0);

  const processFetchResponse = useCallback(({ response, compStreamObjectRef, negotiationObj, currentIndex, ManualReview, }) => {
    console.log('processing fetch stream response')
    const regularComps = [response[0]];
    const optimizedComps = [response[1]];
    const evalDate = response[3];

      const inventoryUpdate = negotiationObj.inventoryUpdateRun ? true : false; 
      // console.log('is it an inventory update?',inventoryUpdate)
      const lookupArray = inventoryUpdate ? negotiationObj.originalPidOrder: negotiationObj.uids;
      // console.log('orig pid order',negotiationObj.originalPidOrder)
      // console.log('uid order',negotiationObj.uids)
      // console.log('the original lookup array to find index',lookupArray)
      // console.log('inventory update?',inventoryUpdate)
      const updatedArray = compStreamObjectRef?.updated || new Array(lookupArray.length).fill(false); // use the existing array
    // Determine whether to use negotiationObj.uids or negotiationObj.cases
    // Map over response[0] and update the updatedArray
      const parcelId = response[0].parcel_id[0]; // extract parcel_id[0] // parcel id should be the parcel ID of hte subject in question?
      // console.log('getting back this pid first',parcelId)
      // console.log('parcel id',parcelId)
      const indexInLookupArray = lookupArray.findIndex(item => item === parcelId);
      // console.log('what is the original index of this client?',indexInLookupArray)

      // This whole piece should be handled in the callback function in whatever component is invoking this, not in this stream parser itself?
    if (negotiationObj.manualReview === 1) {
      if (currentIndex === 1) {
        compStreamObjectRef.properties = [regularComps, optimizedComps];
        // if(inventoryUpdate){
        compStreamObjectRef.properties = [
          new Array(lookupArray.length).fill(null), // First nested array
          new Array(lookupArray.length).fill(null)  // Second nested array
        ];
        compStreamObjectRef.updated = new Array(lookupArray.length).fill(false);
        compStreamObjectRef.updated[indexInLookupArray] = true;
        updatedArray[indexInLookupArray] = true;

        // Ensure getCompSheet and getOptimizedComps are defined and initialized
        const updatedRegularComps = getCompSheet ? cloneDeep(getCompSheet) : [];
        const updatedOptimizedComps = getOptimizedComps ? cloneDeep(getOptimizedComps) : [];

        // Update specific indices within compStreamObjectRef
        compStreamObjectRef.properties[0][indexInLookupArray] = response[0];
        compStreamObjectRef.properties[1][indexInLookupArray] = response[1];

        // Update local copies
        updatedRegularComps[indexInLookupArray] = response[0];
        updatedOptimizedComps[indexInLookupArray] = response[1];

        //  non INVENTORY SPECIFIC FUNCTIONALITY:
        if(!inventoryUpdate){
          setCompSheet(updatedRegularComps);
          setOptimizedComps(updatedOptimizedComps);
          setRar(regularComps[0].RAR[0]);
          setEq(regularComps[0].EQ[0]);
          setEvalDate(evalDate);
        }
          // setReviewPage(true)

        }
        // if a different index than 1:
      else { // this navigation should only really happen in knn run not in the update inventory.
        // if(inventoryUpdate){ // only update invdividual entry if you're in the inventory update.
          // Every one done here shoul be flagged as updated FALSE
          updatedArray[indexInLookupArray] = true;
          compStreamObjectRef.properties[0][indexInLookupArray] = response[0]
          compStreamObjectRef.properties[1][indexInLookupArray] = response[1]
      }
    //   REVISIT THIS AFTER
      // non-manual review processing function: -- nEED TO FIX
    } else {
      console.log(`processing non-manual review, batch number ${currentIndex}`)
      // console.log('no manual review')
      if (!compStreamObjectRef || !compStreamObjectRef.properties || compStreamObjectRef.properties.length === 0) {
        console.log('no compref, instantiating')
        compStreamObjectRef.properties = [regularComps, optimizedComps];
      } else {
        console.log(' the appended comps')
        console.log(compStreamObjectRef.properties[0].concat(regularComps))
        compStreamObjectRef.properties[0] = compStreamObjectRef.properties[0].concat(regularComps);
        compStreamObjectRef.properties[1] = compStreamObjectRef.properties[1].concat(optimizedComps);
      }
    }

    // Update fetch iteration ref and state
    iterationRef.current = currentIndex;

    // if(inventoryUpdate){ // flag this to be true
      compStreamObjectRef.updated[indexInLookupArray] = true;
    // }
    // Make this a hybrid so that there is a 'set updated'
    if (setFetchIterationCallback) {
      // if(inventoryUpdate){ // pass back the actual index if its the update version
      // console.log('passing in: ',indexInLookupArray)
        setFetchIterationCallback(indexInLookupArray);
      // }else{
      // setFetchIterationCallback(iterationRef.current);
      // }
    } else {
      console.warn('setFetchIterationCallback is not provided');
    }

  }, [setCompSheet, loading, setOptimizedComps, setFetchIterationCallback, getCompSheet, getOptimizedComps]);

  const processStream = useCallback(async ({ compStreamObjectRef, stream, negotiationObj, resetIteration=false }) => {
    setLoading(true);

    try {
      const reader = stream.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = '';
      // Reset your count if you re-call function (ie: from inventory)
      let iterationCount = resetIteration ? 0 : iterationRef.current;

      while (true) { 
        const { done, value } = await reader.read();
        if (done) {
          // console.log('Stream completed');
          iterationRef.current = 0;
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        const parts = buffer.split('\n');
        buffer = parts.pop();
        
        for (const part of parts) {
          if (part.trim() !== "") {
            try {
              const update = JSON.parse(part);
              iterationCount += 1;
              // console.log("Processing stream update:", update);

              processFetchResponse({
                response: update,
                compStreamObjectRef: compStreamObjectRef,
                negotiationObj: negotiationObj,
                currentIndex: iterationCount,
              });
            } catch (err) {
              console.error("Error parsing JSON:", err, part);
            }
          }
        }
      }

      if (buffer.trim() !== "") {
        try {
          const finalUpdate = JSON.parse(buffer);
          // console.log("Final update from stream:", finalUpdate);
          processFetchResponse({
            response: finalUpdate,
            globalCompRef: compStreamObjectRef,
            negotiationObj: negotiationObj,
            currentIndex: iterationCount,
            finalUpdate: true,
          });
        } catch (err) {
          console.error("Error parsing final buffer JSON:", err, buffer);
        }
      }
      console.log('Stream is finished.');
      setLoading(false);
    } catch (error) {
      console.error("Error in stream processing:", error);
    } finally {
      setLoading(false);
    }
  }, [processFetchResponse]);

  return {
    processStream,
    loading, // This will now reflect the current iteration count in the invoking component
    // You should return your compref here? unless you can just reference it.
  };
}
