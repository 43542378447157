import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from '../store.js';
import { toast } from 'react-toastify';
import _, {isEqual} from 'lodash';
import Button from '@mui/material/Button';
import CompSearch from './CompSearch.js';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function CaseAnalysisNavigationTopNav({}){
  // setloading can be deleted once your save comps is fixed.
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const compString = queryParams.get('comp') || null;
    // if you need to use this here it is.
    // const comp = compString ? parseInt(compString-1) : null;

    // This means you don't update on the state update, only on the review page load.
    const memoizedNegotiationCases = useMemo(() => getNegotiationObj.cases, [getNegotiationObj.cases]);
    // console.log('reloading')

    const handleSelectSubject = useCallback(({suggestion, index}) => {
      const queryParams = new URLSearchParams(location.search);
      console.log('selected subject')
      console.log(suggestion) // full address string
      setReviewPage(false)
      queryParams.set('comp', index + 1);
      // navigate to the new url
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }, [memoizedNegotiationCases, location.search]);

    const handleBackToAnalysis = useCallback(() => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('comp');
      // navigate to the new url
    }, [location.search]);

    return(
      <div className={`relative testing inline overflow-clip min-h-[400px]`}>

        {/* Fix this weird conditional styling, why is it even doing that? */}
          <div id='sticky' className={`my-0.5 flex justify-between items-center ${getReviewPage?'mb-[-35px]':''} border-b-1 border-gray-500`}>
              <div className="flex flex-col gap-3 sm:flex-row sm:items-center ">
                {/* Breadcrumbs below */}
                <nav>
                  <ol className="flex items-center gap-1">
                    <li className='flex'>

                      {/* Go back to analysis - only display if no comp value */}
                      { getReviewPage ? (
                      <NavLink to={`/analysis?${queryParams}`} className='flex items-center justify-center my-1' onClick={()=>handleBackToAnalysis()}>
                      <Button
                        // variant='contained'
                        variant='outlined'
                        sx={{ padding: '2px 8px' }}
                        startIcon={<KeyboardBackspaceIcon />}
                      >
                        Run New Area
                      </Button>
                      </NavLink>
                      )
                      :
                      // Button to go back to the review summary table
                        <Button onClick={()=>setReviewPage(true)}
                          startIcon={<KeyboardBackspaceIcon/>}
                          className='text-[#1976d2] font-medium'
                        >
                          {/* Here, put your specific breadcrumbs */}
                          {
                            (courtDate !== '' || MuniCode !== 'All') && (
                              <li className={`flex ${courtDate !== '' ? '' : 'text-center items-center mr-0.5'}`}>
                                {courtDate !== '' ? (
                                  <>
                                    {courtDate} - <span className="text-primary ml-1">{MuniCode + ' (' + taxYear + ')'}</span>
                                  </>
                                ) : (
                                  <div id="text" className="text-[#1976d2] font-medium" style={{ lineHeight: '1' }}>
                                    {MuniCode + ' (' + taxYear + ') '}
                                  </div>
                                )}
                              </li>
                            )
                          }
                          {' '}Summary
                        </Button>
                      }
                    </li>
                    
                    {/* Breadcrumb display - County / Muni / SCAR ER # */}
                  </ol>
                </nav>

                {/* Can also place search here if want on the left side?? */}
              </div>

              {/* PASS IN REQUIRED PROPS TO SEARCH HERE: */}
              <div className="flex items-center">
                <CompSearch
                placeholderText='Subject Search'
                compSelectCallback={handleSelectSubject}
                searchData={memoizedNegotiationCases}
                ></CompSearch>
              </div>
          </div>
    </div>
)
}export default React.memo(CaseAnalysisNavigationTopNav)
