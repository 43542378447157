import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GraphParams from './GraphParams.js';
import dayjs from 'dayjs';
import AxisOverlay from './AxisOverlay.js';
import axiosInstance from '../../axiosConfig.js';
import VisualizationTable from './VisualizationTable.js';
import GraphlyPlot from './GraphlyPlot.js';
// import MuniSelectionV2 from '../CaseReview/MuniSelectionV2.js';

function createTableRows({ data, lengthKey, headerMap }) {
  // Determine the number of rows based on lengthKey
  const length = data[lengthKey]?.length || 0;
  const keys = Object.keys(headerMap);
  console.log('Keys from headerMap:', keys);

  // Pre-fetch the data arrays based on headerMap to avoid repeated lookups
  const mappedData = keys.reduce((acc, key) => {
    // Use the field property if it exists, otherwise use the key itself
    const fieldName = headerMap[key]?.field || key;
    acc[key] = data[fieldName] || [];
    return acc;
  }, {});

  return Array.from({ length }, (_, index) => {
    // Initialize each row with id and '#'
    const row = { id: index, '#': index + 1 };

    // Populate the row with only necessary fields
    keys.forEach((key) => {
      row[key] = mappedData[key][index] || '';
    });

    return row;
  });
}

// function createTableRows({ data, lengthKey, headerMap }) {
//   // Determine the number of rows based on the length of the array at lengthKey
//   const length = data[lengthKey]?.length || 0;
//   console.log('Length determined from lengthKey:', length);
//   console.log(headerMap)

//   // Get the keys from the headerMap (these correspond to the column names)
//   const keys = Object.keys(headerMap);
//   console.log('Keys from headerMap:', keys);

//   // Construct the rows array
//   return Array.from({ length }, (_, index) => {
//     // Initialize each row with a unique `id` and an index-based identifier `#`
//     const row = { id: index, '#': index + 1 };

//     // Log the current index being processed
//     console.log(`Creating row for index: ${index}`);

//     // Populate the row with each key's value at the current index
//     keys.forEach((key) => {
//       const fieldName = headerMap[key].field;
//       const value = (data[fieldName] || [])[index] || '';
//       // console.log(`Key: ${key}, Field: ${fieldName}, Value at index ${index}:`, value);
//       row[key] = value;
//     });

//     // Log the completed row
//     console.log('Constructed row:', row);
//     return row;
//   });
// }

function createHeaders(headerMap) {
  return Object.keys(headerMap).map(key => ({
    field: key,
    headerName: headerMap[key].headerName || key,
    align: headerMap[key].align || 'left',
    width: headerMap[key].width || 75,
    flex: headerMap[key].flex || 1,
    type: headerMap[key].type || 'string'
  }));
}

// Main function that combines rows and headers
export function createTable({ data, lengthKey, headerMap }) {
  // const rows = createTableRows({ data, lengthKey, headerMap });
  const rows = data
  const headers = createHeaders(headerMap);
  return [rows, headers];
}

const GraphOverlay = memo(({ nyDataObj, yearDropdownOptions }) => { // top level "filter" which renders the axes in their desired locations
  // create a sub component for
  // THIS IS FOR VISUALIZATION TABLE
  const headerMap = {
    // Sample of how to custom define a field. This doesn't include custom value getters / setters atm.
    Address: {
      headerName: 'Address',
      align: 'left',
      width: 75,
      flex: 1,
      type: 'string',
      field: 'Address'
    },
    ParcelID: {
      headerName: 'Parcel ID',
      align: 'center',
      width: 120,
      flex: 1,
      type: 'string',
      field: 'ParcelID'
    },
    
    // AssessDelta_2018_2017: {
    //   headerName: 'Assess Delta 2018-2017',
    //   align: 'right',
    //   width: 100,
    //   flex: 1,
    //   type: 'numeric',
    //   field: 'AssessDelta_2018_2017'
    // },
    // AssessDelta_2019_2018: {
    //   headerName: 'Assess Delta 2019-2018',
    //   align: 'right',
    //   width: 100,
    //   flex: 1,
    //   type: 'numeric',
    //   field: 'AssessDelta_2019_2018'
    // },
  
    // Rest of the fields are left with default values
    // AssessDelta_2020_2019: {},
    // AssessDelta_2021_2020: {},
    // AssessDelta_2022_2021: {},
    // AssessDelta_2023_2022: {},
    AssessDelta_2024_2023: {},
    // Assessment_2017: {},
    // Assessment_2018: {},
    // Assessment_2019: {},
    // Assessment_2020: {},
    // Assessment_2021: {},
    // Assessment_2022: {},
    // Assessment_2023: {},
    Assessment_2024: {},
    // Client_2017: {},
    // Client_2018: {},
    // Client_2019: {},
    // Client_2020: {},
    // Client_2021: {},
    // Client_2022: {},
    // Client_2023: {},
    // DaysOffset_20170701: {},
    // EvalDate: {}, // these are all year tagged
    // IFMVDelta_2018_2017: {},
    // IFMVDelta_2019_2018: {},
    // IFMVDelta_2020_2019: {},
    // IFMVDelta_2021_2020: {},
    // IFMVDelta_2022_2021: {},
    IFMVDelta_2023_2022: {},
    IFMVDelta_2024_2023: {},
    // IFMV_2017: {},
    // IFMV_2018: {},
    // IFMV_2019: {},
    // IFMV_2020: {},
    // IFMV_2021: {},
    // IFMV_2022: {},
    // IFMV_2023: {},
    IFMV_2024: {},
    SalePrice: {},
    SaleDate: {},
    TimeAdjPct_20170701: {},
    TimeDelta: {},
    // spot_RAR_2017: {},
    // spot_RAR_2018: {},
    // spot_RAR_2019: {},
    // spot_RAR_2020: {},
    // spot_RAR_2021: {},
    // spot_RAR_2022: {},
    // spot_RAR_2023: {},
    spot_RAR_2024: {},
    // spot_RAR_timeAdj_2017: {},
    // spot_RAR_timeAdj_2018: {},
    // spot_RAR_timeAdj_2019: {},
    // spot_RAR_timeAdj_2020: {},
    // spot_RAR_timeAdj_2021: {},
    // spot_RAR_timeAdj_2022: {},
    // spot_RAR_timeAdj_2023: {},
    spot_RAR_timeAdj_2024: {}
  };
  
  const [timeAdjOverride, setTimeAdjOverride] = useState('')
  const memoizedTimeAdjOverride = useMemo(() => timeAdjOverride, [timeAdjOverride]);
  const [microLoad, setMicroLoad] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [taxYear, setTaxYear] = useState('');
  const memoizedTaxYear = useMemo(() => taxYear, [taxYear]);
  const [baseYear, setBaseYear] = useState('');
  const memoizedBaseYear = useMemo(() => baseYear, [baseYear]);
  const [plot, setPlot] = useState('');
  const [chartType, setChartType] = useState('');
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const municipality = queryParams.get('municipality') || 'All';
  const county = queryParams.get('county') || 'All';
  const [graphError, setGraphError] = useState(null)
  const [displayMode, setDisplayMode] = React.useState('graph');
  const [axes, setAxes] = useState({ xAxis: '', yAxis: '', zAxis: ''});
  const memoizedAxes = useMemo(() => axes, [axes]);
  const [storedFilter, setStoredFilter] = useState(null);
  const memoizedFilter = useMemo(() => storedFilter, [storedFilter]);
  const [summaryStats, setSummaryStats] = useState(null)

  // this flow was never updated once values were parameterized. There are duplicate effectivetaxyear values in diff fucntions here with different defaults.
  // 
  // Fixed this flow for the error now that years arent defined by defualt.
  const handleParamsChange = useCallback((value, label) => {
    console.log('Received value:', value, 'for label:', label);
    // Update the base year and tax year based on the label
    if (label === 'Base Year') {
      // console.log('Updating Base Year:', value);
      setBaseYear(value);
    } else if (label === 'Tax Year') {
      // console.log('Updating Tax Year:', value);
      setTaxYear(value);
    } else if (label === 'Time Adjustment') {
      // console.log('Updating Time Adjustment:', value);
      setTimeAdjOverride(value);
    }

    // This is setting the value of taxyear
    // Log the response of this axes update:
    setAxes((prevAxes) => {
      console.log('Updating axes based on new baseYear and taxYear');
      // Initialize new axes based on previous state
      const updatedAxes = { ...prevAxes };
      console.log(updatedAxes)
  
      // We need to update based on the label passed in
      if (label === 'Base Year' && baseYear) {
        // Only update axes if baseYear has a non-blank value
        updatedAxes.xAxis = updatedAxes.xAxis.replace(baseYear, value);
        updatedAxes.yAxis = updatedAxes.yAxis.replace(baseYear, value);
        updatedAxes.zAxis = updatedAxes.zAxis.replace(baseYear, value);
      } else if (label === 'Tax Year' && taxYear) {
        // Only update if the new taxYear value is non-blank
        updatedAxes.xAxis = updatedAxes.xAxis.replace(taxYear, value);
        updatedAxes.yAxis = updatedAxes.yAxis.replace(taxYear, value);
        updatedAxes.zAxis = updatedAxes.zAxis.replace(taxYear, value);
      }
  
      console.log('Updated Axes:', updatedAxes);
      return updatedAxes;
    });
  }, [baseYear, taxYear, setBaseYear, setTaxYear, setTimeAdjOverride, axes, setAxes]);
  

  const graphDependencies = useMemo(() => ({ // the functions will re-script when these values change.
    baseYear,
    taxYear,
    MuniCode,
    county,
    timeAdjOverride,
    storedFilter,
    memoizedAxes,
    chartType,
  }), [baseYear, taxYear, MuniCode, county, timeAdjOverride, storedFilter, axes, chartType]);

  // based on charttype, axis options shoul dbe constrained (ie: can't represent a 1d axis on 2d chart)
  // this can be memoized and returned from a function response (theoretically can change on chart type)
  const axisOptions = useMemo(() => {
    // Why are you setting this here
    // Set default values if baseYear or taxYear are blank
    const effectiveTaxYear = taxYear || 2018;   // Default to 2018 if taxYear is blank
    const effectiveBaseYear = baseYear || effectiveTaxYear-1; // Default to 2017 if baseYear is blank
  
    return [
      { value: 'SalePrice', label: 'Sale Price', type: 'numeric' },
      { value: 'SaleDate', label: 'Sale Date', type: 'date' },
      // { value: 'SaleMonth', label: 'Sale Month', type: 'numeric' },
      // { value: 'SaleYear', label: 'Sale Year', type: 'numeric' },
      // { value: 'Address', label: 'Address', type: 'numeric' },
      // { value: 'Year', label: 'Year', type: 'numeric' },
      // { value: 'NeighborhoodCode', label: 'Neighborhood Code', type: 'numeric' },
      { value: `IFMV_${effectiveTaxYear}`, label: `IFMV ${effectiveTaxYear}`, type: 'numeric' },
      { value: `IFMV_${effectiveBaseYear}`, label: `IFMV ${effectiveBaseYear}`, type: 'numeric' },
      { value: 'Assessment', label: "Assessment", type: 'numeric' },
      { value: `Assessment_${effectiveBaseYear}`, label: `Assessment ${effectiveBaseYear}`, type: 'numeric' },
      { value: `spot_RAR_${effectiveTaxYear}`, label: 'Calculated RAR', type: 'numeric' },
      { value: `spot_RAR_timeAdj_${effectiveTaxYear}`, label: `Time Adj RAR`, type: 'numeric' },
      { value: `spot_RAR_${effectiveBaseYear}`, label: `RAR using ${effectiveBaseYear} AV`, type: 'numeric' },
      { value: `spot_RAR_timeAdj_${effectiveBaseYear}`, label: `Time Adj RAR using ${effectiveBaseYear} AV`, type: 'numeric' },
      { value: `AssessDelta_${effectiveTaxYear}_${effectiveBaseYear}`, label: `Assessment Δ ${effectiveBaseYear} -> ${effectiveTaxYear}`, type: 'numeric' },
      { value: 'TimeDelta', label: 'Time Adj', type: 'numeric' },
      { value: 'Sold', label: 'Sold', type: 'bool' },
      // { value: 'RegridConvertedSqFt', label: 'regrid sqft', type: 'numeric' },
      { value: 'Sqft', label: 'Sqft', type: 'numeric' },
      { value: 'Style', label: 'Style', type: 'string' },
      { value: 'Acres', label: 'Acres', type: 'numeric' },
      // { value: 'Zip5', label: 'zip', type: 'numeric' },
      // { value: 'high_end', label: 'High End', type: 'bool' },
      // { value: 'Client', label: 'Client', type: 'numeric' },
      // { value: 'RAR', label: 'RAR' }, // this is the static RAR
      // What other fields do we want to include from the axes
    ];
  }, [baseYear, taxYear]);
  

  const [headers, setHeaders] = useState([ // update this state as you change your axes
    {
        id: 'index',
        numeric: false,
        disablePadding: true,
        alignment: 'left',
        style: 'text-xs',
        label: 'Index',
    },
    {
        id: 'axis1',
        numeric: false,
        disablePadding: true,
        alignment: 'left',
        label: 'Address',
    },
  ]);
  const [rows, setRows] = useState([]);
  // this getTaxyear nmay be the commonality theme rerendering everything

  const loadGraph = useCallback(async ({
    graphType,
    axes = { xAxis: '', yAxis: '', zAxis: '' },
    filter = memoizedFilter,
    linear = false
  }) => {

    console.log(axes)

    if(filter){ // store your filter, for subsequent graph update runs.
      setStoredFilter(filter)
    }
    setPlot('loading')
    setChartType(graphType)
    setMicroLoad(true)
    console.log(MuniCode)
    console.log(county)
    if(MuniCode === 'All' && county === 'All'){
        setPlot('')
        setMicroLoad(false)
        setGraphError('select a County and Municipality')
    return
    }else{
        setGraphError(null)
    }

    const axesMap = {
      'Distribution': 1,
      'X,Y Scatter': 2,
      '3-D Scatter': 3,
    };
    let numRequiredAxes = axesMap[graphType] || 0;

    // Filter axes to get non-blank values
    const nonEmptyAxes = Object.values(axes).filter(value => value !== '');
    console.log(nonEmptyAxes)
    // const effectiveBaseYear = baseYear || 2017; // Default to 2017 if baseYear is blank
    // const effectiveTaxYear = taxYear || 2018;   // Default to 2018 if taxYear is blank
    const effectiveBaseYear = baseYear || 2023; // Default to 2017 if baseYear is blank
    const effectiveTaxYear = taxYear || 2024  ;   // Default to 2018 if taxYear is blank
    console.log(effectiveTaxYear)
    
    // If number of non-empty axes is less than required, get the default axes (program axis values)
    if (nonEmptyAxes.length < numRequiredAxes) {
      // Default axes for the program with explicit keys
      const programAxisDefaults = {
        xAxis: 'Sqft',
        yAxis: 'Acres',
        zAxis: 'SalePrice'
      };
    
      console.log('Using default axes (based on keys):', programAxisDefaults);
    
      // Fill in missing axes with program values based on keys
      Object.keys(axes).forEach((key) => {
        if (axes[key] === '' && nonEmptyAxes.length < numRequiredAxes) {
          axes[key] = programAxisDefaults[key]; // Use default for the specific axis
          nonEmptyAxes.push(axes[key]); // Track the added axis
        }
      });
    
      // Ensure only the required number of axes are present
      const allAxes = Object.entries(axes)
        .filter(([_, value]) => value !== '') // Keep only non-empty values
        .slice(0, numRequiredAxes); // Limit to the required number of axes
    
      // Reassign updated axes back to the original keys
      Object.keys(axes).forEach((key, index) => {
        axes[key] = allAxes.find(([axisKey]) => axisKey === key)?.[1] || ''; // Match keys or reset
      });
    
      console.log('Not enough axes passed, updating adtl axes to defaults.')
      // see if it double renders may need to move this state set out of here.
      setAxes(prevAxes => ({
        ...prevAxes,
        xAxis: axes.xAxis || prevAxes.xAxis,
        yAxis: axes.yAxis || prevAxes.yAxis,
        zAxis: axes.zAxis || prevAxes.zAxis
      }));
    }

    // Initialize visualization object
    const visualizationObject = {
        MuniCode: MuniCode,
        TaxYear: effectiveTaxYear,
        BaseYear: effectiveBaseYear,
        timeAdjOverride: memoizedTimeAdjOverride,
        PlotType: graphType,
        // This is used for the demo for ML vs non-ml.
        linear: linear, // pass linear flag based on which option is selected for 3d.
        Axes: axes,
        ...(filter &&  filter ), // this includes groups.
    };

    console.log('params being passed in:')
    console.log(visualizationObject)
    try{
    const vizResponse = await axiosInstance.post(`/get_plot`, visualizationObject);
    console.log('viz response')
    console.log(vizResponse)
    // console.log(JSON.parse(vizResponse.data[0])) // see what response is coming back as
    const visualization = JSON.parse(vizResponse.data[0])
    const summaryStats = vizResponse.data[1]
    const tableInfo = vizResponse.data[2]
    console.log(tableInfo)
    // New table creation function (generalized)
    const tableResponse = createTable({ data: tableInfo, lengthKey: 'Address', headerMap });
    console.log(tableResponse)
    setRows(tableResponse[0])
    setHeaders(tableResponse[1])
    
    setSummaryStats(summaryStats) // use this to display summary stats.
    // plot handling for styling.

    // Move the title to be more centered
    visualization.layout.margin = visualization.layout.margin || {};
    visualization.layout.pad = visualization.layout.pad || {};
    visualization.layout.autosize = true;
    visualization.layout.margin.l = 260; 
    visualization.layout.margin.r = 180;
    if (graphType === 'Distribution') { // figure out sizing of components for non-3d chart
      // don't need this anymore, but in case we need custom handling for htis in the future.
    } else if (graphType === 'X,Y Scatter') {
        // axis1 = visualization.layout.xaxis.title.text;
        visualization.layout.margin.r = 0
    } else if (graphType === '3-D Scatter') {
        visualization.layout.scene.camera = visualization.layout.scene.camera || {};
        visualization.layout.scene.camera.eye = visualization.layout.scene.camera.eye || {};
        visualization.layout.scene.camera.eye.z = 1.35; // changed default here to prevent cutoff corner. 1.4 is fully non-cut.
      }

        visualization.layout.title.x = 0.5; // Horizontal center (0 is left, 1 is right)
        visualization.layout.title.y = 0.95; // Vertical center (0 is bottom, 1 is top)
        visualization.layout.title.xanchor = 'center'; // Adjust alignment relative to x position

    // Finally, set your plot + table value:
    console.log(visualization)
    setPlot(visualization)
    // Could update this so it isnt a creation function but just set the state for the data returned, and pass into modular table component.
    }catch(error){
      console.log(error)
    setPlot('')
    setGraphError(`Uncaught Data error: ${error}`)
    }
    console.log('setting load to false')
    setMicroLoad(false)
  },[graphDependencies])

  // This top level also contains the 
    const handleAxisChange = useCallback((axisLabel, value) => {
      setAxes((prevAxes) => ({ // THIS updates your axis values in the parent.
        ...prevAxes,
        [axisLabel]: value
      }));
    },[]);

  const formatFilterObject = useCallback((filters) => {
    // Create a new array by mapping over the original filters
    const updatedFilters = filters.flatMap((filter) => {
        // If filter type is 'date', format the date value and return a new filter object
        console.log(filter)
        // bandaid filtering fix, need to set the dtype of the saleDate filter, it snot coming through.
        if (filter.dataType  === 'date' || filter.column==='SaleDate') {
          console.log('filter')

            const formattedDate = dayjs(filter.value).format('YYYY-MM-DD');
            return { ...filter, value: formattedDate, column: 'SaleDate' };
        } 
        // If filter type is 'dateRange', return two new filters: one for 'from' and one for 'to'
        else if (filter.dataType === 'dateRange') {
            const newFilters = [];
            // Handle 'from' date
            if (filter.value.hasOwnProperty('from')) {
                const fromDate = dayjs(filter.value.from).format('YYYY-MM-DD');
                newFilters.push({ ...filter, value: fromDate, operator: '>', column: 'SaleDate', dataType: 'date' });
            }
            // Handle 'to' date
            if (filter.value.hasOwnProperty('to')) {
                const toDate = dayjs(filter.value.to).format('YYYY-MM-DD');
                newFilters.push({ ...filter, value: toDate, operator: '<', column: 'SaleDate', dataType: 'date' });
            }
            return newFilters; // `flatMap` will handle the flattening of the array
        }
        // If not a date or dateRange, return the filter as is
        return filter;
    });

    // Reduce the updatedFilters to create a filter object, separating Filters and Groups
    const filterObject = updatedFilters.reduce((acc, curr) => {
        if (curr.type === 'Filter') {
            acc.Filters.push(curr);
        } else if (curr.type === 'Group') {
            // You can sort groups here if needed
            acc.Groups.push(curr);
        }
        return acc;
    }, { Filters: [], Groups: [] });

    console.log('filterObject', filterObject);
    return filterObject;
  }, []);

  // Stupid to een use this, but works for now.
  const handleUpdateGraph = useCallback(
    async ({ graphType, filter = memoizedFilter, linear }) => {
      setMicroLoad(true);
      try {
        // Ensure proper named arguments are passed to loadGraph
        await loadGraph({
          graphType: graphType, 
          axes: memoizedAxes, // Use memoizedAxes
          filter, // Default to memoizedFilter if not provided
          linear: linear
        });
      } catch (error) {
        console.error('Error loading graph:', error);
      } finally {
        setMicroLoad(false);
      }
    },
    [memoizedAxes, memoizedFilter, setMicroLoad] // Include dependencies
  );
  
  const onFilterChange = useCallback(
    (filter) => {
      // Format the filter object
      const processedFilter = formatFilterObject(filter);
  
      // Check if chartType exists and update the graph
      if (chartType) {
        handleUpdateGraph({ 
          graphType: chartType, 
          filter: processedFilter 
        });
      }
    },
    [chartType, handleUpdateGraph] // Ensure dependencies are correct
  );
  


  return (
    <div className='flex flex-col h-full'>
      {/* Top section for graph params. */}
        <div className='bg-white rounded-md flex flex-col items-center min-h-[90px] mb-2'>
          <div className='text-center font-bold'>
            Visualization Parameters:
          </div>
          {/* <MuniSelectionV2 user={true} /> */}
            <GraphParams nyDataObj={nyDataObj} handleUpdateGraph={handleUpdateGraph} taxYear={memoizedTaxYear} timeAdjOverride={memoizedTimeAdjOverride} handleParamsChange={handleParamsChange} baseYear={memoizedBaseYear} microLoad={microLoad} setMicroLoad={setMicroLoad} yearDropdownOptions={yearDropdownOptions} 
            />
        </div>

      {/* Graph content container */}
      <div className='flex flex-col flex-grow bg-white'>
          <div className='relative flex-grow justify-center items-center'>
          
          {/* Graph vs. data view selection */}
          {/* <div className='flex absolute top-0 left-0 group'>
            <button 
              className={`px-0.5 z-50 border border-gray-300 bg-white hover:border-black ${displayMode === 'graph' ? '[&&]:border-blue-500 border-2' : ''}`} 
              style={{ minWidth: '3.25rem', minHeight: '1.5rem' }} 
              onClick={() => setDisplayMode('graph')}>
              Graph
            </button>
            <button 
              className={`px-1 z-50 border hover:border-black bg-white border-gray-300 ${displayMode === 'data' ? '[&&]:border-blue-500 border-2' : ''}`} 
              style={{ minWidth: '3.25rem', minHeight: '1.5rem' }} 
              onClick={() => setDisplayMode('data')}>
              Data
            </button>
          </div> */}

          {/* Axis and filter overlay */}
          {displayMode === 'data' ? (
            <div className='flex flex-grow justify-center items-center w-full h-full'>
              <VisualizationTable
                headers={headers}
                rows={rows}
                MuniCode={MuniCode}
              />
            </div>
          ) : (
            <>
          <AxisOverlay
            axes={axes}
            handleAxisChange={handleAxisChange}
            axisOptions={axisOptions}
            onFilterChange={onFilterChange}
            summaryStats={summaryStats}
          />
              
            {/* // HERE IS WHERE THE GRAPH CONTENT IS RENDERED */}
              <div className='flex flex-grow relative h-full justify-center items-center'>
                {graphError && (
                  <div className="flex justify-center items-center h-full ">
                    <div className="text-meta-1 pb-24">Error: {graphError}</div>
                  </div>
                ) }
                {microLoad ? (
                  <div className="flex justify-center items-center h-full">
                    <div className="h-16 w-16 animate-spin z-[500] rounded-full border-4 border-solid border-primary border-t-transparent" />
                  </div>
                ) : 
                plot && (
                  <div className='flex flex-grow w-full h-full'>
                  <GraphlyPlot
                  plot={plot}
                  />
                  </div>
                )}
              </div>
              </>
            )}
          </div>
      </div>
    </div>
  );
  })

  export default GraphOverlay;