import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

// Containerized MUI dropdown selection (small) component - really dropdown list
const DropdownSection = React.memo(({ dropdowns, onDropdownChange, title }) => {
  const handleChange = (key, value) => {
    onDropdownChange(key, value);
  };

  return (
    // update for mobile styling
    <div className="p-4 pb-1 shadow-md rounded-lg bg-white">
      {/* Title Section */}
      <Typography 
        variant="h5" 
        component="h2" 
        align="center" 
        gutterBottom
        className="mb-4"
      >
        {title}
      </Typography>

      {/* Dropdown Section */}
      <div className="grid grid-cols-2 gap-4 md:flex justify-center">

      {dropdowns.map(({ label, options, value, key, disabled, width, error }, index) => (
        <FormControl
          size="small"
          className='relative'
          key={index}
          sx={{ minWidth: width }}
          disabled={disabled}
          error={!!error} // Material UI's error state
        >
          <InputLabel
            id={`${label.toLowerCase()}-select-label`}
            className="z-10 bg-white"
          >
            {label}
          </InputLabel>
          <Select
            labelId={`${label.toLowerCase()}-select-label`}
            value={value}
            onChange={(e) => handleChange(key, e.target.value)}
            label={label}
            className="truncate"
          >
            <MenuItem
              value="All"
              disabled={options.some(({ disabled }) => disabled) || key === 'TaxYear'}
            >
              <span>Select All</span>
            </MenuItem>
            {options.map(({ key: optionKey, value: optionValue, displayText, disabled }) => (
              <MenuItem
                key={optionKey}
                disabled={disabled}
                value={optionValue}
                className="truncate"
              >
                {displayText}
              </MenuItem>
            ))}
          </Select>
            <FormHelperText
            sx={{
              visibility: error ? 'visible' : 'hidden', // Show error only when it exists
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {error || ' '}
          </FormHelperText>
        </FormControl>
      ))}
    </div>
  </div>
  );
});

export default DropdownSection;
