import { PDFDownloadLink, pdf, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { usePersistedDataStore, useCompStore } from '../store';
import { PDFDocument } from 'pdf-lib';
import { cloneDeep } from 'lodash';

const styles = StyleSheet.create({
  logo: {
      width: '30px',
      height: '35px',
      marginRight: '10px'
  },
  signature: {
      width: '100px',
      height: '30px',
      marginRight: '20px'
  },  
  text: {
      fontFamily: 'Helvetica',
      fontSize: '9px',
      marginRight: '25px'
  },
  title: {
      fontFamily: 'Helvetica',
      fontSize: '22px',
  },  
  table: { 
      display: "table",
      marginBottom: 3
  },
  tableRow: {
      flexDirection: "row", 
  },
  tableCol:{
      borderStyle: "solid", 
      borderWidth: 1, 
      borderColor: '#f2f2f2',
  },
  tableCellHeader:{
      fontSize: '7px',
      paddingTop: 2,
      paddingBottom: 2,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'left',
      border:"1px solid #f2f2f2"
  },
  tableCell:{
      fontSize: '7px',
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 3,
      fontFamily: 'Helvetica',
  },
})


// export async function GetCompAdjustments(parcel_id){
// }

// export async function GetAllCompAdjustments(parcelid){
// }

// export async function GetCompInventory(parcelid, year, village, compsJson){
// }

// export async function merge(master, addition){
// }

// update the getcompphotos function
export async function GetCompPhotos(pidArray){

  //assigns the subject and others to placeholder as default
  let photos = Object.fromEntries(
    pidArray.map(item => [item, "https://placehold.co/150x100/jpg"]) // Using item directly as parcel ID
  );
  try{
      const subjectPhoto = await fetch("https://aventine-photos.s3.amazonaws.com/"+pidArray[0]+".jpg")
      if (subjectPhoto.status===200){
          photos[pidArray[0]] = "https://aventine-photos.s3.amazonaws.com/"+pidArray[0]+".jpg"
      }
  }catch (error){
      console.log(error)
  }
  // remove the first entry from this arr.
  pidArray.shift()
  //checkes the existence of photos in S3 and overides them if the exist
  await Promise.all(
    pidArray.map(async (ParcelID) => {
          try{
              const results = await fetch("https://aventine-photos.s3.amazonaws.com/"+ParcelID+".jpg")
              if (results.status===200){
                  photos[ParcelID] = "https://aventine-photos.s3.amazonaws.com/"+ParcelID+".jpg"
              }
          } catch(error){
              console.log(error)
          }
      })
  )
  return photos
}

// compare across compsheet for the most superior feature to use for ajustments if you are missing inventory (for subj)
const findBest = (item, compsList) => {
  //going through comps
  let best = 0
  for (var comp of compsList){
      //if it exists and its greater than
      if(comp[item] && (comp[item] > best)){
          best = comp[item]
      }
  }
  return best
}


// Include compNumber as an override if your user selected in the summary table
// This is mostly heinous and retarded, but took it from claudia and not going to reinvent the wheel until necessary.
export async function CompReportGenerator({ compNumber, userName, miscAdjustments, selectedRows, options }) {
    // for adjustment parameters you should aboslutely define an options array to know if you should use IFMV adj, etc.
    // use selectred rows to get the indices of the comps you want to use.
    const { compSheet, negotiationObj, evalDate } = usePersistedDataStore.getState();
    const reportData = compSheet[compNumber]
    // console.log(reportData)
    const { adjustments } = useCompStore.getState();
    // add in the subject PID as the 0th index of selected rows:
    const photosPIDArray = cloneDeep(selectedRows)
    photosPIDArray.unshift(reportData.parcel_id[0])
    const compPhotos = await GetCompPhotos(photosPIDArray)
    const caseInformation = negotiationObj.cases[compNumber]

    // find the index of the parcel_id contained in the selectedRows in the reportData.parcel_id array
    const indexOfCompsArray = []
    const maxEntries = 5; // Limit to the first 5 entries

    for (const row of selectedRows) {
        if (indexOfCompsArray.length < maxEntries) {
            const index = reportData.parcel_id.indexOf(row);
            if (index !== -1) { // Check if the row exists in parcel_id
                indexOfCompsArray.push(index);
            }
        } else {
            break; // Exit loop after pushing 5 entries
        }
    }

    const adjustment = (subject, comp, adjust) => {
    
        // Validate if subject and adjust are numbers
        if (typeof subject === 'number' && !isNaN(subject) && 
            typeof adjust === 'number' && !isNaN(adjust)) {
    
            // If comp is not a valid number, set it to zero
            const validComp = (typeof comp === 'number' && !isNaN(comp)) ? comp : 0;
    
            // Calculate adjustment with either comp or zero
            return parseInt((subject - validComp) * adjust).toLocaleString('en-US');
        } else {
            return "0";
        }
    };
    
    // for Misc Adjustments, should just incorporate that into your query and the object response you have above. shouldn't need to pass in as you curr are.
    // utilize this to construct your data object.
  try{
        let useSalePrice = false
        let subject = {};
        let compsList = [];
        for (const key in reportData) {
            if (Array.isArray(reportData[key])) {
                // Get the 0th value for the subject
                subject[key] = reportData[key][0];

                // Create comps for indices found in indexOfCompsArray
                for (let i = 0; i < indexOfCompsArray.length; i++) {
                    const compIndex = indexOfCompsArray[i];

                    if (compIndex !== -1 && reportData[key][compIndex] !== undefined) { // Check if the index is valid
                        if (!compsList[i]) { // Initialize the object for the comp if it doesn't exist
                            compsList[i] = {};
                        }
                        compsList[i][key] = reportData[key][compIndex]; // Get the value of the array at the index
                    }
                }
            }
        }

        // UPDATE THIS CODE TO BRING BACK INT HE COMMENTED OUT THINGS
        const data = {
            Subject: {
                "Address": {
                    NumCol: 1,
                    Values: [subject.Address],
                },
                "Town | MLS ID": {
                    NumCol: 2,
                    Values: [subject.Town, subject.MLSNumber]
                },
                "Parcel ID": {
                    NumCol: 1,
                    Values: [subject.parcel_id]
                },
                "PCC | SD | Miles": {
                    NumCol: 3,
                    Values: [subject.PCC, subject.SDCode ? "#"+parseInt(subject.SDCode) : null, "0 miles"]
                },
                "Style | Sold Price": {
                    NumCol: 2,
                    Values: [subject.Style, subject.SalePrice ? subject.SalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}) : null]
                }, 
                "Assessment | LOA": {
                    NumCol: 2,
                    Values: [subject.PropertyAssessment ? subject.PropertyAssessment.toLocaleString('en-US') : null, null]
                },
                "Contract | Title":{
                    NumCol: 3,
                    Values: [
                    // don't have contract date in our response atm
                    subject.ContractDate ? new Date(subject.ContractDate).toLocaleDateString('en-US', {timeZone: 'UTC', month: 'numeric', day: 'numeric', year: '2-digit'}) : null, 
                        subject.SaleDate ? new Date(subject.SaleDate).toLocaleDateString('en-US',{timeZone: 'UTC', month: 'numeric', day: 'numeric', year: '2-digit'}) : null, null]
                },
                "Acres": {
                    NumCol: 3,
                    Values: [subject.Acres ? subject.Acres : findBest('Acres', compsList), (adjustments.LotSqFt * 43506).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/A', null]
                },
                "Beds | Rooms": {
                    NumCol: 2,
                    Values: [subject.Bedrooms && subject.TotalRooms ? subject.Bedrooms+' | '+subject.TotalRooms : null, null]
                },
                "Year Built": {
                    NumCol: 2,
                    Values: [subject.YearBuilt ? subject.YearBuilt : null, null]
                },
                "Bathrooms (F|H)": {
                    NumCol: 3,
                    Values: [(subject.Bath ? subject.Bath : findBest('Bath', compsList))+' | '+(subject.HalfBath ? subject.HalfBath : findBest('HalfBath', compsList)), adjustments.Bath.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/B', null]
                },
                "Interior Sqft": {
                    NumCol: 3,
                    Values: [(subject.Sqft ? Math.round(subject.Sqft).toLocaleString('en-US') : findBest('Sqft', compsList)), adjustments.Sqft.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/SF', null]
                },
            //   we don't have finished basement sqft in our resposne atm
                "Basement Sqft": {
                    NumCol: 3,
                    Values: [subject.FinishedBasement ? subject.FinishedBasement.toLocaleString('en-US') : findBest('FinishedBasement', compsList), (adjustments.FinishedBasement ? adjustments.FinishedBasement.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) : '0')+'/SF', null]
                },
                "CAC | Heat": {
                    NumCol: 3,
                    Values: [(subject.CentralAir===1 ? "T" : subject.CentralAir==='0' ? "F" : '-')+' | '+(subject.Heating ? subject.Heating.substring(0,5) : '-'),adjustments.CentralAir.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/CA', null]
                },
                "Garage": {
                    NumCol: 3,
                    Values: [subject.TotalGarageCap ? subject.TotalGarageCap : findBest('TotalGarageCap', compsList), adjustments.TotalGarageCap.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/Bay', null]
                },
                "Deck | Patio | Porch": {
                    NumCol: 2,
                    Values: [(subject.Deck ? subject.Deck : findBest('Deck', compsList))+' | '+
                        (subject.Patio ? subject.Patio : findBest('Patio', compsList))+' | '+(subject.Porch ? subject.Porch : findBest('Porch', compsList)), null]
                },
                "Fireplaces": {
                    NumCol: 3,
                    Values: [subject.Fireplaces ? subject.Fireplaces : findBest('Fireplaces', compsList), adjustments.Fireplaces.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/FP', null]
                },
                "Inground Pool": {
                    NumCol: 3,
                    Values: [subject.InGroundPool ? subject.InGroundPool : findBest('InGroundPool', compsList), adjustments.InGroundPool.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/P', null]
                },
                "Kitchens": {
                    NumCol: 3,
                    Values: [subject.Kitchens ? subject.Kitchens : findBest('Kitchens', compsList), adjustments.Kitchens.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/K', null]
                }
            }, 
            Comps: compsList.map((item, index)=>{
                const compIndex = indexOfCompsArray[index]
                    const LOA = ((item.PropertyAssessment / item.SalePrice)*100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})+'%'
                    if (subject.parcel_id === item.parcel_id && item.SalePrice<subject.IFMV){ useSalePrice = true }
                    let compData = {
                        "Address": {
                            NumCol: 1,
                            Values: [item.Address],
                            Adjustment: false,
                        },
                        "Town | MLS ID": {
                            NumCol: 2,
                            Values: [item.Town, item.MLSNumber],
                            Adjustment: false,
                        },
                        "Parcel ID": {
                            NumCol: 1,
                            Values: [item.parcel_id],
                            Adjustment: false,
                        },
                        "PCC | SD | Miles": {
                            NumCol: 3,
                            Values: [item.PCC, item.SDCode ? "#"+parseInt(item.SDCode) : '', (item.Distance ? item.Distance.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) : '')+" miles"],
                            Adjustment: false,
                        },
                        "Style | Sold Price": {
                            NumCol: 2,
                            Values: [item.Style, item.SalePrice ? item.SalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}) : ''],
                            Adjustment: false,
                        }, 
                        "Assessment | LOA": {
                            NumCol: 3,
                            Values: [item.PropertyAssessment ? item.PropertyAssessment.toLocaleString('en-US') : "", LOA, null],
                            Adjustment: false,
                        },
                        // still no contract date in your data
                        "Contract | Title":{
                            NumCol: 3,
                            Values: [(item.ContractDate ? new Date(item.ContractDate).toLocaleDateString('en-US', {timeZone: 'UTC', month: 'numeric', day: 'numeric', year: '2-digit'}) : ""), 
                                (item.SaleDate ? new Date(item.SaleDate).toLocaleDateString('en-US', {timeZone: 'UTC', month: 'numeric', day: 'numeric', year: '2-digit'}) : ""), null],
                            Adjustment: false,
                        },
                        "Acres": {
                            NumCol: 2,
                            Values: [(item.Acres ? item.Acres : ""), adjustment(subject.Acres, item.Acres, (adjustments.LotSqFt*43506))],
                            Adjustment: true,
                        },
                        "Beds | Rooms": {
                            NumCol: 2,
                            Values: [(item.Bedrooms ? item.Bedrooms : "-")+' | '+(item.TotalRooms ? item.TotalRooms : "-"), null],
                            Adjustment: false,
                        },
                        "Year Built": {
                            NumCol: 2,
                            Values: [item.YearBuilt ? item.YearBuilt : '-', null],
                            Adjustment: false,
                        },
                        "Bathrooms (F|H)": {
                            NumCol: 2,
                            Values: [(item.Bath ? item.Bath : '-')+' | '+(item.HalfBath ? item.HalfBath : '-'), adjustment(
                                subject.Bath+(subject.HalfBath/2)> 0 ? subject.Bath+(subject.HalfBath/2) : null,
                                item.Bath+(item.HalfBath/2)> 0 ? item.Bath+(item.HalfBath/2) : null, adjustments.Bath)],
                            Adjustment: true,
                        },
                        "Interior Sqft": {
                            NumCol: 2,
                            Values: [item.Sqft ? item.Sqft.toLocaleString('en-US') : '-', adjustment(subject.Sqft, item.Sqft, adjustments.Sqft)],
                            Adjustment: true,
                        },
                        // How does this work currently for non-finished basement?
                        "Basement Sqft": {
                            NumCol: 2,
                            Values: [
                                item.FinishedBasement || 0,  // Use 0 if FinishedBasement is falsy
                                adjustment(subject.FinishedBasement, item.FinishedBasement || 0, adjustments.FinishedBasement)
                            ],
                            Adjustment: true,
                        },
                        "CAC | Heat": {
                            NumCol: 2,
                            Values: [(item.CentralAir===1 ? "T" : item.CentralAir===0 ? "F" : '-')+' | '+(item.Heating ? item.Heating.substring(0, 10)+'...' : '-'), adjustment(subject.CentralAir, item.CentralAir, adjustments.CentralAir)],
                            Adjustment: true,
                        },
                        "Garage": {
                            NumCol: 2,
                            Values: [item.TotalGarageCap, adjustment(subject.TotalGarageCap, item.TotalGarageCap, adjustments.TotalGarageCap)],
                            Adjustment: true,
                        },
                        "Deck | Patio | Porch": {
                            NumCol: 2,
                            Values: [(item.Deck ? item.Deck : '-')+' | '+(item.Patio ? item.Patio : '-')+' | '+(item.Porch ? item.Porch : '-'), (Number(adjustment(subject.Porch, item.Porch, adjustments.Porch).replace(/,/g, ''))+Number(adjustment(subject.Patio, item.Patio, adjustments.Patio).replace(/,/g, ''))+Number(adjustment(subject.Deck, item.Deck, adjustments.Deck).replace(/,/g, ''))).toLocaleString('en-US')],
                            Adjustment: true,
                        },
                        "Fireplaces": {
                            NumCol: 2,
                            Values: [item.Fireplaces, adjustment(subject.Fireplaces, item.Fireplaces, adjustments.Fireplaces)],
                            Adjustment: true,
                        },
                        "Inground Pool": {
                            NumCol: 2,
                            Values: [item.InGroundPool, adjustment(subject.InGroundPool, item.InGroundPool, adjustments.InGroundPool)],
                            Adjustment: true,
                        },
                        "Kitchens": {
                            NumCol: 2,
                            Values: [item.Kitchens, adjustment(subject.Kitchens, item.Kitchens, adjustments.Kitchens)],
                            Adjustment: true,
                        },
                    }
                    if(miscAdjustments){
                        for(const misc of Object.keys(miscAdjustments)){
                            compData[miscAdjustments[misc].Name] = {
                                NumCol: 2,
                                Values: miscAdjustments[misc].AdjustmentType==="percentage" ? [miscAdjustments[misc].Values[compIndex]==='1' ? 'T' : 'F', ((parseInt(miscAdjustments[misc].Values[0]) - parseInt(miscAdjustments[misc].Values[compIndex]))*((parseInt(miscAdjustments[misc].Adjustment)/100)*item.SalePrice)).toLocaleString('en-US', {maximumFractionDigits:0, minimumFractionDigits: 0})]
                                : [miscAdjustments[misc].Values[compIndex]==='1' ? 'T' : 'F', adjustment(parseInt(miscAdjustments[misc].Values[0]), parseInt(miscAdjustments[misc].Values[compIndex]), parseInt(miscAdjustments[misc].Adjustment))],
                                Adjustment: true,
                            }
                        }
                    }
                    return(compData)
            }) 
        }

        // add in misc adjustments where appropriate
        if(miscAdjustments){
            for(const misc of Object.keys(miscAdjustments)){
                data.Subject[miscAdjustments[misc].Name] = {
                    NumCol: 3,
                    Values:[miscAdjustments[misc].Values[0]==='1' ? 'T' : 'F',miscAdjustments[misc].AdjustmentType==="percentage" ?  parseInt(miscAdjustments[misc].Adjustment)+'%' : parseInt(miscAdjustments[misc].Adjustment).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})+'/'+miscAdjustments[misc].Name.substring(0,1), null]
                }
            }
        }
        const compPrices = []

        const avgLoa = (compPrices.reduce((accumulator, curr) => ((curr.PropertyAssessment / curr.SalePrice) * 100) + accumulator, 0) / compsList.length).toFixed(2);

    const CompReport = () =>  (
      <Document>
                <Page size="A4" orientation='landscape' style={{margin: '15px'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '10px'}}>
                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                            <Image style={styles.logo} src={'https://aventine-images.s3.amazonaws.com/icon.png'} onError={() => console.error(`Failed to load}`)} />
                            <View>
                                <Text style={styles.title}>Aventine Properties</Text>
                                <Text style={{fontSize: '10px', fontFamily: 'Helvetica'}}>Comparables Report</Text>
                            </View>
                        </View>

                        <View style={{paddingTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <Text style={[styles.text, {textAlign: 'right'}]}>Generated On: {new Date().toLocaleDateString('en-US', {timeZone: 'EST', month: 'long', year: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true})} by {userName}</Text>
                            <Text style={styles.text}>Tax Year: {negotiationObj.TaxYear} | District: {subject.Municipality} | Valuation Date: {evalDate} | RAR: {subject.RAR} | EQ Rate: {subject.EQ}</Text>
                        </View>
                    </View>
                    
                    {/* Subject and comp Generation */}
                    <View style={[styles.table, {marginRight: 30}]}>
                        <View style={styles.tableRow}>
                            <View style={{width: '10%',border: '1px solid black'}}>
                                <View style={[styles.tableCellHeader, {paddingTop: '20px', paddingBottom: "18px"}]}>
                                    
                                    {/* ENSURE OWNER NAME IS POPULATED */}
                                    <Text style={{fontSize: '9px', color: 'gray'}}>Owner Name</Text>
                                    <Text style={{fontSize: '9px', overflow: 'hidden', textOverflow: "ellipsis", maxLines: 1}}>{caseInformation.Name ? caseInformation.Name : 'Unknown'}</Text>
                                    <Text style={{fontSize: '9px', color: 'gray'}}>SCAR ID</Text>
                                    {/* NEED TO ENSURE YOUR SCAR ID IS POPULATED */}
                                    <Text style={{fontSize: '9px'}}>{caseInformation.SCARIndexNumber ? caseInformation.SCARIndexNumber : "Unknown"}</Text>
                                </View>
                                <Text style={styles.tableCellHeader}>&nbsp;</Text>
                                <Text style={styles.tableCellHeader}>Address</Text>
                                <Text style={styles.tableCellHeader}>Town | MLS ID</Text>
                                <Text style={styles.tableCellHeader}>Parcel ID</Text>
                                <Text style={styles.tableCellHeader}>PCC | SD | Miles</Text>
                                <Text style={styles.tableCellHeader}>Style | Sold Price</Text>
                                <Text style={styles.tableCellHeader}>Assessment | LOA</Text>
                                <Text style={styles.tableCellHeader}>Contract | Title</Text>
                                <Text style={styles.tableCellHeader}>Acres</Text>
                                <Text style={styles.tableCellHeader}>Beds | Rooms</Text>
                                <Text style={styles.tableCellHeader}>Year Built</Text>
                                <Text style={styles.tableCellHeader}>Bathrooms (F|H)</Text>
                                <Text style={styles.tableCellHeader}>Int Sqft</Text>
                                <Text style={styles.tableCellHeader}>Basement Sqft</Text>
                                <Text style={styles.tableCellHeader}>CAC | Heat</Text>
                                <Text style={styles.tableCellHeader}>Garage</Text>
                                <Text style={styles.tableCellHeader}>Deck|Patio|Porch</Text>
                                <Text style={styles.tableCellHeader}>Fireplaces</Text>
                                <Text style={styles.tableCellHeader}>Pool</Text>
                                <Text style={styles.tableCellHeader}>Kitchens</Text>
                                {miscAdjustments && Object.keys(miscAdjustments).map((key)=>{return(<Text style={styles.tableCellHeader}>{miscAdjustments[key].Name}</Text>)})}
                                <Text style={styles.tableCellHeader}>Total Adjts</Text>
                                <Text style={styles.tableCellHeader}>Adjusted Sold</Text>
                                <Text style={styles.tableCellHeader}>Price of Comp</Text>
                            </View>

                            {/* subject rendering */}
                            <View style={{width: '15%', border: '1px solid black'}}>
                                <View style={styles.table}>  
                                    <View style={styles.tableRow}>
                                        <View style={[{width: '100%',borderBottom: '1px solid black', borderTop: '1px solid black'}]}><Image src={compPhotos[subject.parcel_id]} style={{width: '120px', height: '77px'}}/></View>
                                    </View> 
                                    <View style={styles.tableRow}>
                                        <View style={[{width: '100%', textAlign: 'center',borderBottom: '1px solid black', borderTop: '1px solid black'}]}>
                                            <Text style={[styles.tableCell, {fontFamily: 'Helvetica-Bold', textTransform: 'uppercase'}]}>Subject</Text>
                                        </View>
                                    </View>

                                    {Object.keys(data.Subject).map((item, key)=>{
                                        if(data.Subject[item].NumCol===1){
                                            return (
                                                <View key={key} style={styles.tableRow}>
                                                    <View style={[styles.tableCol, {width: '100%'}]}><Text style={styles.tableCell}>{data.Subject[item].Values[0]}</Text></View>
                                                </View>
                                            )
                                        } else if (data.Subject[item].NumCol===2){
                                            return (
                                                <View key={key} style={styles.tableRow}>
                                                    <View style={[styles.tableCol, {width: '70%'}]}><Text style={styles.tableCell}>{data.Subject[item].Values[0]!==null ? data.Subject[item].Values[0] : <>&nbsp;</>}</Text></View>
                                                    <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{data.Subject[item].Values[1]}</Text></View>
                                                </View>
                                            )
                                        } else {
                                            return (
                                                <View key={key} style={styles.tableRow}>
                                                    <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{data.Subject[item].Values[0]!==null ? data.Subject[item].Values[0] : <>&nbsp;</> }</Text></View>
                                                    <View style={[styles.tableCol, {width: '40%'}]}><Text style={styles.tableCell}>{data.Subject[item].Values[1] ? data.Subject[item].Values[1] : <>&nbsp;</>}</Text></View>
                                                    <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{data.Subject[item].Values[2] ? data.Subject[item].Values[2] : <>&nbsp;</>}</Text></View>
                                                </View>
                                            )
                                        }
                                    })}

                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCol, {width: '70%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                        <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                    </View> 
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCol, {width: '70%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                        <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                    </View> 
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableCol, {width: '70%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                        <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                    </View> 
                                </View> 
                            </View>

                            {/* comp rendering */}
                            {data.Comps.map((prop, key)=>{
                                const compIndex = indexOfCompsArray[key]
                                let totalAdjust = 0
                                let grossTotalAdjust = 0
                                let soldPrice = parseInt(prop["Style | Sold Price"].Values[1].slice(1).replace(/,/g, ''))
                                let netAdjust = 0
                                let grossAdjust = 0
                                let  adjustedPrice = 0

                                // FIX THE ADJUSTMENTS CODE::
                                // if you have living area for both the property in question, and the subject:
                                if((prop["Interior Sqft"] && prop.Acres) && (subject.Sqft && subject.Acres)){
                                    // console.log(`Calculating adjustments based off of inventory diff for comp #${key+1}`)
                                    // shouldn't htis only iterate over relevant adjustments? this is dumb as hell.
                                Object.values(prop).forEach(item=>{
                                    // here, can implement the adjustment grading scale
                                    let adjustment = item.Values[1] ? parseInt(item.Values[1].replace(/,/g, '')) : 0
                                    item.Adjustment ? totalAdjust += adjustment : totalAdjust+=0
                                    item.Adjustment ? grossTotalAdjust += Math.abs(adjustment) : totalAdjust+=0
                                })
                                netAdjust = (totalAdjust/soldPrice)*100
                                grossAdjust = (grossTotalAdjust/soldPrice)*100
                                adjustedPrice = soldPrice + totalAdjust
                              }
                              else{ // CONFIRM THIS WORKS:::::::
                                totalAdjust = subject.IFMV-reportData.IFMV[compIndex]
                                adjustedPrice = (subject.IFMV-reportData.IFMV[compIndex])+prop.SalePrice
                                netAdjust = (totalAdjust/soldPrice)*100
                                grossAdjust = (totalAdjust/soldPrice)*100
                              }
                                compPrices.push(adjustedPrice)
                                return(                                    
                                    <View style={{width: '15%',border: '1px solid black'}} key={key}> 
                                        <View style={styles.table}> 
                                            <View style={styles.tableRow}>
                                                <View style={[{width: '100%',borderBottom: '1px solid black', borderTop: '1px solid black'}]}>
                                                    <Image src={compPhotos[prop["Parcel ID"].Values[0]]} style={{width: '120px', height: '77px'}}/></View>
                                            </View> 
                                            <View style={styles.tableRow}>
                                                <View style={[{width: '100%', textAlign: 'center',borderBottom: '1px solid black', borderTop: '1px solid black'}]}><Text style={[styles.tableCell, {fontFamily: 'Helvetica-Bold', textTransform: 'uppercase'}]}>Comp {key+1}</Text></View>
                                            </View> 

                                            {Object.keys(prop).map((item, key)=>{
                                                if(prop[item].NumCol===1){
                                                    return (
                                                        <View key={key} style={styles.tableRow}>
                                                            <View style={[styles.tableCol, {width: '100%'}]}><Text style={styles.tableCell}>{prop[item].Values[0]}</Text></View>
                                                        </View>
                                                    )
                                                } else if (prop[item].NumCol===2){
                                                    return (
                                                        <View key={key} style={styles.tableRow}>
                                                            <View style={[styles.tableCol, {width: '60%'}]}><Text style={styles.tableCell}>{prop[item].Values[0]}</Text></View>
                                                            <View style={[styles.tableCol, prop[item].Adjustment ? {width: '40%', textAlign: 'right', paddingRight:'3px'} : {width: '40%'}]}><Text style={styles.tableCell}>{prop[item].Values[1]}</Text></View>
                                                        </View>
                                                    )
                                                } else {
                                                    return (
                                                        <View key={key} style={styles.tableRow}>
                                                            <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{prop[item].Values[0]}</Text></View>
                                                            <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{prop[item].Values[1]}</Text></View>
                                                            <View style={[styles.tableCol, {width: '40%'}]}><Text style={styles.tableCell}>{prop[item].Values[2]}</Text></View>
                                                        </View>
                                                    )
                                                }
                                            })}
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: '60%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                                <View style={[styles.tableCol, {width: '40%', textAlign: 'right', paddingRight:'3px'}]}><Text style={[styles.tableCell, {fontFamily: 'Helvetica-Bold'}]}>{totalAdjust.toLocaleString('en-US')}</Text></View>
                                            </View> 
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>Net</Text></View>
                                                <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{netAdjust.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</Text></View>
                                                <View style={[styles.tableCol, {width: '40%'}]}><Text style={styles.tableCell}>&nbsp;</Text></View>
                                            </View> 
                                            <View style={styles.tableRow}>
                                                <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>Gross</Text></View>
                                                <View style={[styles.tableCol, {width: '30%'}]}><Text style={styles.tableCell}>{grossAdjust.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}%</Text></View>
                                                <View style={[styles.tableCol, {width: '40%'}]}><Text style={[styles.tableCell, {fontFamily: 'Helvetica-Bold', textAlign: 'right'}]}>{adjustedPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0})}</Text></View>
                                            </View> 
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>

                    {/* price footer */}
                    <View style={[styles.table, {width: '75%'}]}>
                        <View style={styles.tableRow}>

                            {/* This Subject price is so insanely stupid that it overwrites the subj sales px here instead of making this a var.*/}
                            {/* Have this variable set, and when it is set, fire a BE fucntion  */}
                            <Text style={[styles.tableCol, {padding: 1, width: '15%', fontSize: '8px', fontFamily: 'Helvetica'}]}>Subject Market Value</Text>
                            <Text style={[styles.tableCol, {padding: 1, width: '10%', fontSize: '8px', fontFamily: 'Helvetica-Bold', backgroundColor: '#f2f2f2'}]}>{useSalePrice ? subject.SalePrice ? subject.SalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) : 'N/A' : compPrices.length>0 ? parseInt(compPrices.reduce((x,y) => x+y, 0)/compPrices.length).toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) :  subject.IFMV ? subject.IFMV.toLocaleString() : "N/A"}</Text>
                            <Text style={[styles.tableCol, {padding: 1, width: '15%', fontSize: '8px', fontFamily: 'Helvetica'}]}>Req Assessment</Text>
                            <Text style={[styles.tableCol, {padding: 1, width: '10%', fontSize: '8px', fontFamily: 'Helvetica-Bold', backgroundColor: '#f2f2f2'}]}>{useSalePrice ? parseInt((subject.SalePrice)*subject.RAR).toLocaleString() : compPrices.length>0 ? parseInt((compPrices.reduce((x,y) => x+y, 0)/compPrices.length)*subject.RAR).toLocaleString() : subject.PropertyAssessment ? subject.PropertyAssessment.toLocaleString() : "N/A"}</Text>                            
                            <Text style={[styles.tableCol, {padding: 1, width: '10%', fontSize: '8px', fontFamily: 'Helvetica'}]}>IFMV</Text>
                            <Text style={[styles.tableCol, {padding: 1, width: '10%', fontSize: '8px', fontFamily: 'Helvetica-Bold', backgroundColor: '#f2f2f2'}]}>{subject.IFMV ? subject.IFMV.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) : 0}</Text>
                            <Text style={[styles.tableCol, {padding: 1, width: '10%', fontSize: '8px', fontFamily: 'Helvetica'}]}>Difference</Text>
                            <Text style={[styles.tableCol, {padding: 1,width: '5%', fontSize: '8px', fontFamily: 'Helvetica-Bold', backgroundColor: '#f2f2f2'}]}>{useSalePrice ? parseInt(((subject.IFMV - subject.SalePrice)/subject.IFMV)*100) : compPrices.length>0 ? parseInt(((subject.IFMV - parseInt((compPrices.reduce((x,y) => x+y, 0)/compPrices.length)))/subject.IFMV)*100) : 0}%</Text>
                            <Text style={[styles.tableCol, {padding: 1,width: '7%', fontSize: '8px', fontFamily: 'Helvetica'}]}>Avg LOA</Text>
                            <Text style={[styles.tableCol, {padding: 1,width: '8%', fontSize: '8px', fontFamily: 'Helvetica-Bold', backgroundColor: '#f2f2f2'}]}>{avgLoa ? avgLoa.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2}) : 0}%</Text>
                        </View>
                    </View>
   
                    <View style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between'}}> 
                        <Text style={styles.text}>Comparables used in this report are closed sales similar to the Subject property in size, appeal and marketability.{'\n'}
                        I estimate that this report reflects the Market Value of the Subject property as of the appropriate Valuation Date. {'\n'}
                        Telephone: (631) 673-6738 | Fax: (631) 673-6706 | Address: 33 Walt Whitman Rd Ste 200B Huntington Station NY 11746</Text>
                        <View>
                            <Text style={styles.text}>Signature:</Text>
                            <Image style={styles.signature} src="https://aventine-images.s3.amazonaws.com/emails/signature.png"/>
                        </View>
                    </View>
                </Page>
            </Document>
    );
    const reportPDF = await pdf(<CompReport/>).toBlob()
    // here you can fire the savecomp price report function if a single run, otherwise it should pass this to parent and save all.
    // Just do the saveone for now.
    // saveCompPrice(subject.parcel_id, compPrices)
    console.log("Generated PDF Blob:", reportPDF);
    return reportPDF
    }catch(error){
        console.error(error)
        return error
    }
  };

//   THIS WENT RIGHT BELOW THE COMP REPORT SECTION:
    // if(subject.Latitude && subject.Longitude && options.typeReport==="onepagerMaps"){
    //     let reportBuffer = await reportPDF.arrayBuffer()
    //     const pdfDoc = await PDFDocument.load(reportBuffer) 
    //     const pages = pdfDoc.getPages()
    //     const firstPage = pages[0]
    //     const {height} = firstPage.getSize()
        
    //     // removing map funcitonality for now
    //     // const newPage = pdfDoc.addPage()
    //     // let birdview = `https://maps.googleapis.com/maps/api/staticmap?center=${subject.Latitude},${subject.Longitude}&size=1280x720&zoom=20&format=jpg&maptype=satellite&key=AIzaSyD2HtmbOy44VqhNpOMcQWEhrS4G22eHLNY`
    //     // let imgbirdview = await fetch(birdview)
    //     // let imgbirdviewBuffer = await imgbirdview.arrayBuffer()
    //     // const birdviewImage = await pdfDoc.embedJpg(imgbirdviewBuffer)
    //     // newPage.drawImage(birdviewImage, {
    //     //     x: 130,
    //     //     y: (height / 2) - 350,
    //     //     width: 350,
    //     //     height: 350
    //     // })
    //     // let mapsLink = `https://maps.googleapis.com/maps/api/staticmap?&size=1280x720&markers=size:small%7Ccolor:red%7Clabel:*%7C${subject.Latitude},${subject.Longitude}`
    //     // let mapsLinkTail = '&format=jpg&maptype=roadmap&key=AIzaSyD2HtmbOy44VqhNpOMcQWEhrS4G22eHLNY'
    //     // for (var val in compsList){
    //     //     let comp = compsList[val]
    //     //     if(comp.Latitude && comp.Longitude){
    //     //         mapsLink+=`&markers=color:blue%7Clabel:${parseInt(val)+1}%7C${comp.Latitude},${comp.Longitude}`
    //     //     }
    //     // }
    //     // mapsLink+=mapsLinkTail
    //     // let imgmap = await fetch(mapsLink)
    //     // let imgmapBuffer = await imgmap.arrayBuffer()
    //     // const mapImage = await pdfDoc.embedJpg(imgmapBuffer)
    //     // newPage.drawImage(mapImage, {
    //     //     x: 130,
    //     //     y: (height / 2) + 50,
    //     //     width: 350,
    //     //     height: 350
    //     // })

    // }
