import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import GavelIcon from '@mui/icons-material/Gavel'


function SummaryCards({ summaryData, taxYear, municipalityString }) {

    return(
    <div className="flex-shrink-0">
    <div className="flex flex-col justify-center overflow-hidden">
      <Typography variant="h5" align="center" gutterBottom>
        {municipalityString} Summary Stats
      </Typography>

        <Grid container spacing={3} justifyContent="center" className='mb-1'>
          
          {/* Total Property Value Card */}
          <Grid item xs={12} sm={3} >
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Left-aligned title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left'}}>
                  Total Property Value
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Property Value Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      ${summaryData?.totalPropertyValue.toLocaleString()}
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MapsHomeWorkOutlinedIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Total Property Tax Card */}
          <Grid item xs={12} sm={3}>
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Full-width title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left'}}>
                  Total Property Tax
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Property Tax Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      ${summaryData?.totalPropertyTax.toLocaleString()}
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AccountBalanceIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Annual Time Trend Card */}
          <Grid item xs={12} sm={3}>
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Full-width title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left' }}>
                  {taxYear
                    ? `'${taxYear.toString().slice(2, 4)} Time Trend`
                    : 'Time Trend'}
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Trend Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      {(summaryData?.annualTrend * 100).toFixed(2)}%
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {summaryData?.annualTrend > 0 ? (
                      <TrendingUpIcon sx={{ fontSize: '3rem', color: 'success.main' }} />
                    ) : (
                      <TrendingDownIcon sx={{ fontSize: '3rem', color: 'error.main' }} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


          {/* Properties grieved (display potential rev shortfall) Card */}
          <Grid item xs={12} sm={3}>
            <Card elevation={6} sx={{ borderRadius: 2, boxShadow: 1 }}>
              <CardContent>
                {/* Header: Left-aligned title */}
                <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'left', }}>
                  Properties Grieved
                </Typography>

                {/* Body: Icon and information in flex layout */}
                <Grid container alignItems="center" spacing={2}>
                  {/* Grievance Amount Information */}
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div">
                      ${summaryData?.propertiesGrieved.toLocaleString()}
                    </Typography>
                  </Grid>

                  {/* Icon */}
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <GavelIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


        </Grid>
        </div>
  </div>
)
} export default SummaryCards