import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import { useNYData } from '../../common/NyDataObject';
import { useSearchParams } from 'react-router-dom';
import DropdownSection from '../../common/DropdownSelectList';
// import { useAuth } from '../AuthContextProvider';
import {useUser} from '../UserContext'
import { getDefaultYear } from '../../common/helper_functions';

// based on user's access level, dictate what they can select.
// may want to abstract this authentication to a higher level hook or something in the future..
// for this to be secure we need to validate against the user's accesss level on BE (because they could change disabled state / send reqs programatically)
// I didn't build this with county level access in mind. will need to revisit with a county contract. shouldn't be a huge refactor from this
function MuniSelectionV2({ error, handleClearError, locationParams, updateLocationParams }) {
  // Parse userAccess for restrictions
  // this component shoudl use the strings from params to set the value of the title
    // with relevant specificity - if there is a village, show the village name, otherwise muni, otherwise county, otherwise State.
  const {user} = useUser();
  const userAccess = user?.userAccess || { level: '', years: [] };
  const userRestricted = userAccess.level !== 'all' && userAccess.level !== 'aventine';
  const defaultTaxYear = useMemo(() => {
    const currentYear = getDefaultYear();
    return userAccess?.years?.includes(currentYear) ? currentYear : userAccess?.years?.[0] || currentYear;
  }, [userAccess?.years]);

  const states = ['NY', 'TX', 'FL', 'CA', 'OH'];
  const [searchParams, setSearchParams] = useSearchParams();
  const { getCounties, getMunicipalities, getVillages } = useNYData();

  const { state, county, municipality, village, TaxYear, municipalityString, countyString, villageString } = locationParams;

  const title = useMemo(() => {
    // Depending on the specificity of the selection, show the corresponding title
    if (villageString && villageString !== 'All') {
      return `Village of ${villageString}`;
    } else if (municipalityString && municipalityString !== 'All') {
      return `Town of ${municipalityString}`;
    } else if (countyString) {
      const countyWord = countyString === 'All' ? 'Counties' : 'County';
      return `${countyString} ${countyWord}`;
    }
    return state + ' State';
  }, [villageString, municipalityString, countyString]);
  // Get initial values based on userAccess
  const counties = getCounties();
  const countyLookup = useMemo(
    () => Object.fromEntries(counties.map(({ key, selectVal }) => [selectVal, key])),
    [counties]
  );
  
  const municipalities = useMemo(() => {
    if (county) {
      // Use selectedCounty for dynamic lookup
      return getMunicipalities(countyLookup[county]) || [];
    }
    // Fallback to initialCounty if no selection
    return county ? getMunicipalities(countyLookup[county]) : [];
  }, [county, county, getMunicipalities, countyLookup, county]);
  
  const municipalityLookup = useMemo(
    () => Object.fromEntries(municipalities.map(({ key, selectVal }) => [selectVal, key])),
    [municipalities]
  );
  
  const villages = useMemo(() => {
    if (municipality && county) {
      // Use municipality and county for dynamic lookup
      return getVillages(countyLookup[county], municipalityLookup[municipality]) || [];
    }
    // Fallback to initialMunicipality and county if no selection
    return county && municipality
      ? getVillages(countyLookup[county], municipalityLookup[municipality])
      : [];
  }, [
    municipality,
    county,
    getVillages,
    countyLookup,
    municipalityLookup,
  ]);
  const villageLookup = useMemo(
    () => Object.fromEntries(villages.map(({ key, selectVal }) => [selectVal, key])),
    [villages]
  );
  

  // updates the user's search params based on what is allowed.
  // this is a little hacky and I don't love it, but working fine for now.
  // the upper level param extraction is probably superior to this. this componenet isn't critical in the rendering stack though so left it.
  // only run the useeffect on mount. This should really just be default param extraction and not a side effect.
  useEffect(() => {
    console.log('useEffect is running');
    const params = new URLSearchParams(searchParams);
    const updates = {}; // Consolidate updates here
    const clearParams = (params, keys) => {
      keys.forEach((k) => {
        params.delete(k);
        updates[k] = ''; // Clear corresponding values in updates
      });
    };
  
    // State update logic
    if (userRestricted) {
      if (state && params.get('state') !== state) {
        params.set('state', state);
        updates['state'] = state;
        clearParams(params, [
          'county', 'countyString', 'municipality', 'municipalityString', 'village', 'villageString', 'MuniCode',
        ]);
      }

      // console.log('the county',county)
      if (county && params.get('county') !== county) {
        params.set('county', county);
        updates['county'] = county;
        updates['countyString'] = countyLookup[county] || 'All';
        updates['MuniCode'] = county;
        clearParams(params, ['municipality', 'municipalityString', 'village', 'villageString']);
      }
  
      if (municipality && params.get('municipality') !== municipality) {
        const muniCode = params.get('MuniCode') || county;
        params.set('municipality', municipality);
        params.set('municipalityString', municipalityLookup[municipality] || 'All');
        updates['municipality'] = municipality;
        updates['municipalityString'] = municipalityLookup[municipality] || 'All';
        updates['MuniCode'] =
          municipality !== 'All' ? muniCode + municipality : muniCode.slice(0, 1);
        clearParams(params, ['village', 'villageString']);
      }
  
      if (villages.length === 1 && params.get('village') !== villages[0].selectVal) {
        const muniCode = params.get('MuniCode') || county + municipality;
        params.set('village', villages[0].selectVal);
        params.set('villageString', villageLookup[villages[0].selectVal] || 'All');
        updates['village'] = villages[0].selectVal;
        updates['villageString'] = villageLookup[villages[0].selectVal] || 'All';
        updates['MuniCode'] =
          villages[0].selectVal !== 'All' ? muniCode.slice(0, 1) + villages[0].selectVal : muniCode.slice(0, 3);
      }
    }
  
    // Tax year update logic
    // const defaultTaxYearStr = defaultTaxYear.toString();
    // if (!params.get('TaxYear')) {
    //   params.set('TaxYear', defaultTaxYearStr);
    //   updates['TaxYear'] = defaultTaxYear;
    // }
  
    // Only perform updates if there are changes
    if (Object.keys(updates).length > 0) {
      setSearchParams(params, { replace: true });
      updateLocationParams(updates); // Consolidate updates in one state update
    }
  }, []);
  

  // This param update should really be handled at the upper level function to make this cleaner, but this was a relic, and I didn't want to refactor it.
  const handleDropdownChange = useCallback(
    (key, value) => {
      const updates = { [key]: value }; // Start with the primary key-value update
      const clearParams = (params, keys) => {
        keys.forEach((k) => {
          params.delete(k);
          updates[k] = ''; // Clear corresponding values in `updates`
        });
      };
  
      switch (key) {
        case 'state':
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('state', value);
            updates['state'] = value;
  
            // Reset dependent fields
            clearParams(params, ['county','countyString','municipality','municipalityString','village','villageString','MuniCode',]);
            return params;
          });
          break;
  
        case 'county':
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('county', value);
            params.set('countyString', countyLookup[value] || 'All');
            params.set('MuniCode', value);
            updates['county'] = value;
            updates['countyString'] = countyLookup[value] || 'All';
            updates['MuniCode'] = value;
  
            // Reset dependent fields
            clearParams(params, ['municipality', 'municipalityString', 'village', 'villageString']);
            return params;
          });
          break;
  
        case 'municipality':
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            // const muniCode = params.get('MuniCode') || '';
            const county = params.get('county') || '';
            const newMuniCode = value !== 'All' ? county + value : county
            params.set('municipality', value);
            params.set('municipalityString', municipalityLookup[value] || 'All');
            params.set('MuniCode', newMuniCode);
            updates['municipality'] = value;
            updates['municipalityString'] = municipalityLookup[value] || 'All';
            updates['MuniCode'] = newMuniCode;
  
            // Reset dependent fields
            clearParams(params, ['village', 'villageString']);
            return params;
          });
          break;
  
        case 'village':
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            const muniCode = params.get('MuniCode') || '';
            const newMuniCode = value !== 'All' ? muniCode.slice(0, 1) + value : muniCode.slice(0, 3);
            params.set('village', value);
            params.set('villageString', villageLookup[value] || 'All');
            params.set('MuniCode', newMuniCode);
            updates['village'] = value;
            updates['villageString'] = villageLookup[value] || 'All';
            updates['MuniCode'] = newMuniCode;
  
            // Reset unrelated fields
            // clearParams(params, ['municipality', 'municipalityString', 'county', 'countyString']);
            return params;
          });
          break;
  
        case 'TaxYear':
          setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            const currentTaxYear = params.get('TaxYear');
            if (currentTaxYear !== value) {
              params.set('TaxYear', value);
              updates['TaxYear'] = value;
            }
            return params;
          });
          break;
  
        default:
          break;
      }
  
      updateLocationParams(updates); // Consolidate all updates into a single state update
    },
    [setSearchParams, updateLocationParams]
  );

  const dropdowns = useMemo(() => [
    // State
    {
      key: 'state',
      label: 'State',
      options: states.map((state) => ({
        key: state,
        value: state,
        displayText: state,
        // disabled: userRestricted && state !== state, // Restrict selection only if user is restricted
        disabled: state !== state, // Restrict selection only if user is restricted
      })),
      value: state,
      width: 60,
    },
    // County
    {
      key: 'county',
      label: 'County',
      options: counties.map(({ key, selectVal, nameWithSelectVal }) => ({
        key,
        value: selectVal,
        displayText: nameWithSelectVal,
        disabled: userRestricted && selectVal !== county, // Restrict selection only if user is restricted
      })),
      value: county,
      disabled: userRestricted && !county, // Disable dropdown entirely if no county access is defined
      // error state here, if value exists, error = true.
      error: error?.county,
      width: 130,
    },
    // Municipality
    {
      key: 'municipality',
      label: 'Municipality',
      options: municipalities.map(({ key, selectVal, nameWithSelectVal }) => ({
        key,
        value: selectVal,
        displayText: nameWithSelectVal,
        disabled: userRestricted && selectVal !== municipality, // Restrict selection only if user is restricted
      })),
      value: municipality,
      error: error?.municipality,
      disabled: (userRestricted || municipalities.length===0) && (!county || county === 'All' || municipalities.length === 0),
      width: 130,
    },
    // Village
    {
      key: 'village',
      label: 'Village',
      options: villages.map(({ key, selectVal, nameWithSelectVal }) => ({
        key,
        value: selectVal,
        displayText: nameWithSelectVal,
      })),
      value: village,
      // error: error?.village,
      disabled: (userRestricted || villages.length === 0) && (!municipality || villages.length === 0), // Restrict selection only if user is restricted
      width: 130,
    },
    // Tax Year
    {
      key: 'TaxYear',
      label: 'Tax Year',
      options: (() => {
        const allYears = [
          defaultTaxYear + 1,
          defaultTaxYear,
          defaultTaxYear - 1,
        ].map((year) => ({
          key: year.toString(),
          value: year.toString(),
          displayText: year.toString(),
          disabled: userRestricted && !userAccess.years.includes(year), // Disable years not in the access list
        }));
        return allYears;
      })(),
      value: TaxYear || '',
      error: error?.TaxYear,
      disabled: userRestricted && userAccess.years.length === 0, // Disable if no years are allowed
      width: 110,
    },
  ], [
    states,
    counties,
    municipalities,
    villages,
    error,
    state,
    county,
    municipality,
    village,
    TaxYear,
    userRestricted, // Dependency for restriction flag
  ]);

  return <DropdownSection dropdowns={dropdowns} onDropdownChange={handleDropdownChange} title={title}/>;
}export default MuniSelectionV2;