import React, { useState, useEffect, useMemo, useRef, useCallback, Suspense } from "react";
import CaseReview from "./CaseReview";
import { usePersistedDataStore, useLocalDataStore,} from "../store";
import CaseAnalysisNavigationTopNav from "./CaseAnalysisNavigationTopNav";
import ReviewSettlements from "./ReviewSettlements";
import _ from "lodash";
import cloneDeep from 'lodash/cloneDeep';
import { useStreamProcessorNew } from '../../common/useStreamProcessorNew';
import {Navigate} from 'react-router-dom';


// const LazyReviewSettlements = React.lazy(() => import('./ReviewSettlements'));


// Should be changed so you don't memoize at the COMP level, memoize whole object (once done) and pass down.
// your stream + state updating re-rendering THIS is probably wrong, if possible to do at lower level
// perhaps, you can abstract some of this to params to enable memoization of this component w/ the base level objects?
// this is a future round of optimizations

// Here, if you are on getreview page, have an interval which calls the state update
function IndividualAnalysisContainer({globalCompRef, compStreamObject}) {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const getReviewPage = useLocalDataStore((state) => state.reviewPage);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);
  const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const getFilterIndexes = usePersistedDataStore((state) => state.filterIndexes);
  const setFilterIndexes = usePersistedDataStore((state) => state.setFilterIndexes);
  // const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
  const [updatedArray, setUpdatedArray] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const comp = useMemo(() => ( parseInt(queryParams.get('comp')) - 1 || 0), [queryParams]);
  // this is setting the value, but the negotiation state isnt there yet (i think)
  const memoizedOptimizedComps = useMemo(() => getOptimizedComps, [getOptimizedComps]);
  const memoizedCompSheet = useMemo(() => getCompSheet, [getCompSheet]);
  // memoize your negotiation object and update it for changes for 
  const memoizedNegotiationObj = useMemo(() => getNegotiationObj.cases, [getNegotiationObj.cases]);
  // const memoizedNegotiationObj = useMemo(() => getNegotiationObj, [getNegotiationObj]);
  // const memoizedNegotiationCases = useMemo(() => getNegotiationObj.cases, [getNegotiationObj.cases]);
  // This function doesn't really do what you need - 
  const handleUpdateResults = useCallback((index) => {
    // console.log('response index', index);
    // Check if the index already exists in the updatedArray
    if(index === 1){
    // setUpdatedArray((prevArray) => {
    //   // Check if the index already exists in the array
    //   const existingIndex = prevArray.find((item) => item.index === index);
  
    //   if (existingIndex) {
    //     // If the index exists, update its value to true
    //     return prevArray.map((item) =>
    //       item.index === index ? true : item // Update the value for the specific index
    //     );
    //   } else {
    //     // If the index doesn't exist, return the array with true for the new index
    //     return [...prevArray, true]; // Just return `true` value for the new index
    //   }
    // });
    }
    // handleUpdateStateCallback()
  }, []);

  const { processStream, loading} = useStreamProcessorNew(handleUpdateResults);
  // WHEN YOU RENDER THE COMPGRID - DO NOT HAVE THE NEGOTIATION OBJ IN THE DEPENDENCY ARRAY
  // Don't create re-renders when the casenotes (or offer) are updated.
    const lastFilterRef = useRef(null);
    const [filterType, setFilterType] = useState('all'); // State for the selected filter

      // Track whether fetchKNN has been triggered
  const hasFetchedKNNRef = useRef(false);
  // Track whether polling is active
  const pollingActiveRef = useRef(false);

  // Helper function to fire the KNN fetch if conditions are met
  const triggerKNNFetch = useCallback(() => {
    if (!hasFetchedKNNRef.current && getCompSheet.length === 0 && getIsFetching) {
      fetchKNNModel(getNegotiationObj);
      hasFetchedKNNRef.current = true; // Mark that KNN has been fetched
    }
  }, [getCompSheet.length, getIsFetching, getNegotiationObj]);

  // Initial fetch of KNN when the component mounts
  useEffect(() => {
    triggerKNNFetch();
  }, []); // Run only once on initial load

  // Polling when fetching is true

    const filterIndexes = useCallback((type) => {
    if (type === 'all') {
    // If the filter type is "all", set the filter indexes to undefined
    setFilterIndexes(undefined);
    lastFilterRef.current = undefined;
    return undefined;
    }

    const cases = cloneDeep(getNegotiationObj.cases);

    // Create an array of indexes that meet the criteria
    const indexesToKeep = cases.reduce((indexes, currentCase, index) => {
    let keep = false;

    if (type === 'open') { // "unsettled"
        const scarStageExists = cases.some(c => c.SCARFiled === 1);
        if (scarStageExists) {
            keep = !['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction) && currentCase.SCARFiled === 1;
        } else {
            keep = !['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction);
        }
    } else if (type === 'settled') {
        keep = ['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction);
    } else if (type === 'unreviewed') {
        // Redo this, it should only be kept if Object.keys(currentCase.Comps).length>0
        console.log(currentCase)
        keep = !currentCase.Comps || (typeof currentCase.Comps === 'object' && Object.keys(currentCase.Comps).length === 0);
    // } else if (type === 'noMarketValue') {

        // keep = !currentCase.SubjectMarketValue;
    } else if (type === 'scar') {
        keep = currentCase.RepID !== "" && currentCase.RepID != null || currentCase.SCARFiled === 1;
    }

    // Add index to the array if it meets the criteria
    if (keep) {
        indexes.push(index);
    }

    return indexes;
    }, []);

    if (_.isEqual(indexesToKeep, lastFilterRef.current)) {
    return indexesToKeep;
    }

    setFilterIndexes(indexesToKeep);
    lastFilterRef.current = indexesToKeep;

    return indexesToKeep; // or use it as needed
    }, [getNegotiationObj.cases]);

    const handleFilterChange = useCallback((type) => {
    // console.log('updated filter',type)
    setFilterType(type);
    filterIndexes(type);
    }, [filterIndexes]);
    
    // Use update array to re-calculate and set the value of the compsheet.
    
    // const filteredCompSheet = useMemo(() => 
    //   getCompSheet.filter(item => item !== null && item !== undefined),
    //   [getCompSheet]
    // );
  

    const resetUpdateArrayCallback = useCallback((length) => {
      // Assuming filteredCompSheet is accessible in this scope
      setUpdatedArray(new Array(length).fill(false));
    }, []);
    

    const filteredCompSheet = useMemo(() => {
      if (typeof getCompSheet === "string" && getCompSheet.trim() === "") {
        return [];
      }
      return Array.isArray(getCompSheet)
        ? getCompSheet.filter(item => item !== null && item !== undefined)
        : [];
    }, [getCompSheet]);
    
    // Know if you should be updating the sheet - if you re-fetch, it needs to clear out the compref
    const globalHasMoreComps = useMemo(() => {
      const sheetLength = filteredCompSheet.length;
      const compRefLength = globalCompRef.current?.properties?.[0]?.length || 0;
      return compRefLength > sheetLength;
    }, [globalCompRef, filteredCompSheet]);
    


    const handleUpdateStateCallback = useCallback((resetState) => {
      const filteredCompSheet = getCompSheet.filter(item => item !== null && item !== undefined)
      // console.log('filtered comp sheet',filteredCompSheet)
      const globalHasMoreComps = globalCompRef.current?.properties[0]?.length > filteredCompSheet.length || false;
      let regularCompsToUpdate = [...getCompSheet];
      let optimizedCompsToUpdate = [...getOptimizedComps];
  
      const updatedArrayNewRef = [...updatedArray];

      // console.log('global has more comps',globalHasMoreComps)
  
      if(globalHasMoreComps){ // it should only put in the non-null values here
        let regularCompsToUpdate = [...globalCompRef.current.properties[0]].filter(comp => comp !== null);
        let optimizedCompsToUpdate  = [...globalCompRef.current.properties[1]].filter(comp => comp !== null);
      
        // Set updated comps
        setCompSheet(regularCompsToUpdate);
        setOptimizedComps(optimizedCompsToUpdate);
        // Update the updated array only for indices of non-null values
        // regularCompsToUpdate.forEach((comp) => {
        //   // Find the index in the original `getCompSheet` (or another reference array if needed)
        //   const index = getCompSheet.findIndex(originalComp => 
        //     originalComp === comp || originalComp.parcel_id === comp.parcel_id
        //   );
      
        //   // If a valid index is found, set it to true
        //   if (index !== -1) {
        //     updatedArrayNewRef[index] = true;
        //   }
        // });
        // Set the updated array
        // setUpdatedArray(updatedArrayNewRef);
      }
      
      // removed the stateful update arr check from here.
      const anyIsUpdatedFalse = 
      ((Array.isArray(compStreamObject.current?.updated) && compStreamObject.current.updated.some(isUpdated => isUpdated)) ||
        updatedArray.some(isUpdated => !isUpdated)) || false;
  
      // console.log('the ORIGINAL update flags:', updatedFlags)
      if (anyIsUpdatedFalse && !globalHasMoreComps) {
        // console.log('any updated = false, the regular comp sheet',regularCompsToUpdate)
        regularCompsToUpdate.forEach((regularComp, index) => {
          // lookup the regulaComp compared to its index position in the compsheet
          if(anyIsUpdatedFalse){
            // console.log('running updated false')
            // this updatedflag should show you if its been updated and set in state or not.
            if(compStreamObject.current.properties.length>0 && updatedArray[index] === false && compStreamObject.current.updated[index] === true){
              // console.log('This index isn\'t updated:', index);
              const newRegularComp = compStreamObject.current.properties[0][index];
              const newOptimizedComp = compStreamObject.current.properties[1][index];
              // Log old and new values
              // console.log(`Comp ${index}: Old regularComp =`, regularComp, ", New regularComp =", newRegularComp);
      
              // Update comps if new values are available
                regularCompsToUpdate[index] = newRegularComp;
                // console.log(`Comp at index ${index} regularComp updated.`,newRegularComp);
      
                optimizedCompsToUpdate[index] = newOptimizedComp;
                // console.log(`Comp at index ${index} optimizedComp updated.`);
      
              // Mark this index as updated
              updatedArrayNewRef[index] = true;
              // here, IF it is index #1 (if that is how it comes in for the first updated comp) - set state of update arr
            }
            // else{
            //   // here a handler for general KNN, set updated array to true
            //   updatedArrayNewRef[index] = true;
            // }
          }
        });
    
        // Set the state outside the loop
        // if(anyIsUpdatedFalse){
          // console.log('the updated flags we are updating with',updatedArrayNewRef)
        setUpdatedArray(updatedArrayNewRef);
        // }
        setCompSheet(regularCompsToUpdate);
        setOptimizedComps(optimizedCompsToUpdate);
        // Also fire filter 
        if(getReviewPage){
          // This is filtering EVERY TIME, which we don't want.
          
          // Revisit filtering.
          if(getFilterIndexes){
          // console.log('filtering from state update')
          // filterIndexes(filterType);
          }
        }
      } else {
        // console.log('No updates were needed. -- HIT THIS "true" ELSE');
        
        if(getReviewPage){
          // Your running this every time even when state updates aren't necessary
          // you could use a ref or smth to track the most recently applied filter?
          if(getFilterIndexes){
            // console.log('filtering')
            // but re-filtering was required
            filterIndexes(filterType);
          }
        }
        // Return true to indicate this is done updating.
          return true;
      }
    }, [getCompSheet, getOptimizedComps, updatedArray, filterType, filterIndexes, getFilterIndexes, getReviewPage]);
    
    const savedCallbackRef = useRef(handleUpdateStateCallback);
  
    useEffect(() => {
      savedCallbackRef.current = handleUpdateStateCallback;
    }, [handleUpdateStateCallback]);

  
    // const startPolling = useCallback(() => {
    //   const intervalId = setInterval(() => {
    //     if (getReviewPage && (getIsFetching || globalHasMoreComps)) {
    //       savedCallbackRef.current(); // Use the latest callback reference
    //     } else {
    //       clearInterval(intervalId); // Clear interval when conditions are no longer met
    //     }
    //   }, 2000);
      
    //   return () => clearInterval(intervalId); // Cleanup interval on unmount
    // }, [getIsFetching, globalHasMoreComps, getReviewPage]);
    
    // useEffect(() => {
    //   if (getIsFetching && getReviewPage) {
    //     const intervalCleanup = startPolling();
    //     return intervalCleanup; // Cleanup interval on unmount
    //   }
    // }, [getIsFetching, startPolling, globalHasMoreComps, getReviewPage]);
    

      // Modify the fetchKNNModel to handle streaming updates, invoke external stream function.
  async function fetchKNNModel(updateNegotiationObj) {
    console.log('negot coming in for fetchknn',updateNegotiationObj)
    // handling the Base URl here bc axios doesn't support streaming in the same way as fetch.
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/KNNModel`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
              MuniCode: updateNegotiationObj.MuniCode,
              TaxYear: parseInt(updateNegotiationObj.TaxYear),
              CourtDate: updateNegotiationObj.CourtDate,
              CourtRun: updateNegotiationObj.CourtRun,
              uids: updateNegotiationObj.uids,
              // RepId: updateNegotiationObj.RepId,
              // Add in manual Review flags?
          }),
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }

    console.log('the response object, body gets passed in. Its just a data with string [{...}')
    console.log(response)
    console.log('negotiationObj going into stream',updateNegotiationObj)
    // and setr updated to []?
    // Here, the process stream and fetch combined function.
    const streamProcessingResponse = await processStream({
      compStreamObjectRef: globalCompRef.current,
      stream: response.body, // pass this in to then get reader.
      negotiationObj: updateNegotiationObj,
    });

    console.log('obj after the process') // this looks like it updated properly?
    console.log(globalCompRef.current,)
    setIsFetching(false);
  }

  if(getCompSheet===''){
    return <Navigate to={`/analysis?${queryParams}`} />
  }

  return (
    <div className="bg-gray-200 flex flex-col h-full">
      <div className="h-full overflow-y-hidden">
        <section className=" overflow-y-hidden max-w-screen-22xl px-6 h-full" id='home'>
          {/* Top navigration above map. */}
          {/* UPDATE DISPLAY FROM GETNEGOTIATION OBJ FOR MUNICODE AND TAXYEAR IN HEADER. */}
          <CaseAnalysisNavigationTopNav 
          ></CaseAnalysisNavigationTopNav>
          <>

          {getReviewPage ?
          <>
          {/* you should lazy load this I think? */}
          <ReviewSettlements
            negotiationCases={getNegotiationObj.cases}
            setFilterType={handleFilterChange}
            filterType={filterType}
            filteredNegotiationCases={getNegotiationObj.cases}
            updatedArray={updatedArray}
            handleUpdateStateCallback={handleUpdateStateCallback}
          >
            </ReviewSettlements>
          </>
          :
          // Here you can render the case review object
          // This is not going to be optimized the way it should be
          // I would like this top level to:
          // hold the higher level negotiation object
          // You need the below container to be the only thing that updates on comp change, 
            <CaseReview 
            // key={resetKey}
              originalCompObject={memoizedCompSheet}
              originalCaseObject={memoizedNegotiationObj} // if you pass in the negotiation object
              originalOptimizedObject={memoizedOptimizedComps}
              // compObject={memoizedCompSheet[comp]}
              // caseObject={memoizedNegotiationObj[comp]} // if you pass in the negotiation object
              // optimizedObject={memoizedOptimizedComps[comp]}
              // savedComps={Object.values(memoizedNegotiationObj[comp].Comps)}

              updatedArray={updatedArray}
              handleUpdateStateCallback={handleUpdateStateCallback}              
              globalCompRef={globalCompRef} // idt you need this here
              // isUpdated={true} // this is not being used in this new version
              compStreamObject={compStreamObject}
              resetUpdateArrayCallback={resetUpdateArrayCallback}
              >
            </CaseReview>
            // null
          }
          </>
        </section>
      </div>
    </div>
  );
}
React.memo(IndividualAnalysisContainer);
IndividualAnalysisContainer.WhyDidYouRender = true;
export default IndividualAnalysisContainer;
