import React, { useState, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import MuniSelectionV2 from './MuniSelectionV2'; // Ensure correct import path
import Button from '@mui/material/Button';
import {useUser} from '../UserContext'
import Container from '@mui/material/Container';
import AnalysisAccordion from './AnalysisAccordion';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { getDefaultYear } from '../../common/helper_functions';
import { toast } from 'react-toastify';
import axiosInstance from '../../axiosConfig'
import { usePersistedDataStore, useCompStore, useLocalDataStore} from '../store';
import { saveComps } from '../../common/saveComps'
import CircularProgress from '@mui/material/CircularProgress';
import {Navigate, NavLink} from 'react-router-dom'
import { isEqual } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNYData } from '../../common/NyDataObject';
import AdvancedSettings from './AdvancedSettings';

// if this doesn't update on comp location change you have succeeded.


// FOR THE OPTIMIZATIONS, follow these practices. https://medium.com/@sagarpatil23399/efficient-react-you-might-not-need-useeffect-95decbf2dbae
// Successsfuly made it so "comp" change doesn't rerender this.
function CaseAnalysisContainer({courtDates, globalCompRef}) {
  console.log('case analaysis rerendering')
  const [runType, setRunType] = useState('all_clients');
  const [additionalInput, setAdditionalInput] = useState(''); // used for repid input or pid
  const [reportType, setReportType] = useState('sales_analysis');
  const [openDialog, setOpenDialog] = useState(null);
  const [propertyType, setPropertyType] = useState('residential');
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [courtDate, setCourtDate] = useState(null);
  const [courtDateOptions, setCourtDateOptions] = useState([]);
  const [selectedCourtDate, setSelectedCourtDate] = useState(null);
  const [repID, setRepID] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState({ county: null, municipality: null, village: null });
  const { user } = useUser();
  const { manualReview, manualReviewOverride, reviewedCasesFlag, showSettledCases, scarOnly } = user;
  const userAccess = user?.userAccess;
  const setIsFetching = usePersistedDataStore((state) => state.setIsFetching);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);
  const [reviewId, setReviewId] = useState(null);
  const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const setAdjustments = useCompStore((state)=> state.setAdjustments)
  const [dataGridRows, setDataGridRows] = useState([]);
  // Extracting location parameters without React Router to prevent their dumbass double render bug
  const [locationParams, setLocationParams] = useState(() => getLocationParams());
  function getLocationParams() {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      state: searchParams.get('state') || 'NY', // defaulting to NY, revisit if you move out of hte state lol.
      county: searchParams.get('county') || '',
      municipality: searchParams.get('municipality') || '',
      village: searchParams.get('village') || '',
      TaxYear: parseInt(searchParams.get('TaxYear') || ''),
      MuniCode: searchParams.get('MuniCode') || '',
      municipalityString: searchParams.get('municipalityString')
    };
  }
  const { getCounties, getMunicipalities, getVillages } = useNYData();
  const { state, county, municipality, village, TaxYear } = locationParams;
  const counties = getCounties();
  const countyLookup = useMemo(
    () => Object.fromEntries(counties.map(({ key, selectVal }) => [selectVal, key])),
    [counties]
  );
  const municipalities = useMemo(() => {
    if (county) {
      // Use selectedCounty for dynamic lookup
      return getMunicipalities(countyLookup[county]) || [];
    }
    // Fallback to county if no selection
    return county ? getMunicipalities(countyLookup[county]) : [];
  }, [county, county, getMunicipalities, countyLookup, county]);
  
  const municipalityLookup = useMemo(
    () => Object.fromEntries(municipalities.map(({ key, selectVal }) => [selectVal, key])),
    [municipalities]
  );

  const updateLocationParams = useCallback((updates) => {
    console.log('updating location params',updates)

    setLocationParams((prev) => {
      // Merge the updates into the current state
      const nextParams = { ...prev, ...updates };
  
      // If the new state is deeply equal to the current state, return the current reference
      if (isEqual(prev, nextParams)) {
        return prev; // Same reference, no re-render
      }
  
      return nextParams; // New reference, triggers re-render
    });
  
    setError((prev) => {
      const updatedErrors = { ...prev };
  
      // Clear errors for any keys in updates
      Object.keys(updates).forEach((key) => {
        if (updatedErrors[key] && updatedErrors[key] !== null) {
          updatedErrors[key] = null;
        }
      });
  
      // If the errors are deeply equal, return the current reference
      if (isEqual(prev, updatedErrors)) {
        return prev; // Same reference, no re-render
      }
  
      return updatedErrors; // New reference, triggers re-render
    });
  }, []);
  // For the courtdate muni selection
    // Need to also return the munistring from this selectedcourtdate function.
  const handleSelectionChange = (event) => {
    const selectedObject = courtDateOptions.find(
      (option) => option.muni === event.target.value
    );
    setSelectedCourtDate(selectedObject.muni);
    // missing
    // , municipalityString:selectedObject.MuniName
    updateLocationParams({municipality:selectedObject.muni.slice(1,3),county:selectedObject.muni.slice(0,1),TaxYear:selectedObject.TaxYear, MuniCode:selectedObject.muni})
    console.log('Selected Option:', selectedObject); // Full object of the selected option
  };

  const handleRunTypeChange = (event) => {
    if (courtDate !== '') {
      setCourtDate('');
    }
    if((runType === 'single_pid' || runType === 'representative') && additionalInput !== '') {
      setAdditionalInput('');
    }

    if(runType === 'court_date' && error.courtDate) {
      setError((prev) => ({
        ...prev,
        courtDate: null,
      }));
    }

    if(runType === 'single_parcel' && error.additionalInput) {
      setError((prev) => ({
        ...prev,
        additionalInput: null,
      }));
    }

    // case to clear the rep input.
    setRunType(event.target.value);
    setAdditionalInput('');
  };

  const clearError = useCallback(
    (key) => {
      if(error[key] && error[key]!==null){
      setError((prev) => ({
        ...prev,
        [key]: null,
      }));
    }
    },
    [setError, error]
  );

  const handleOpenDialog = (value) => setOpenDialog(value);
  const handleCloseDialog = () => setOpenDialog(null);

  // UPDAT ETHIS CODE SO THAT IF THERE IS A SINGLE PID, don't require other params.
  // Some closure issue here
  const runAnalysis = useCallback(
    async ({ accordionId, force=false }) => {
      console.log('running analysis');      
      if (!locationParams.county) {
        setError((prev) => ({
          ...prev,
          county: 'Select a county.',
        }));
        return;
      }

      if(!locationParams.municipality && locationParams.county !== 'All') {
        setError((prev) => ({
          ...prev,
          municipality: 'Select a municipality.',
        }));
        return;
      }

      if(!locationParams.TaxYear){
        setError((prev) => ({
          ...prev,
          TaxYear: 'Select a tax year.',
        }));
        return;
      }

      // if the county, or muni is set to all, then trigger this error.
      if (runType === 'all_clients' && !force && (locationParams.county === 'All' || locationParams.municipality === 'All')) {
        setConfirmationOpen(true);
        return;
      }

      if(runType === 'court_date' && !courtDate) {
        setError((prev) => ({
          ...prev,
          courtDate: 'Select a court date.',
        }));
        return;
      }

      if((runType === 'single_parcel' || runType === 'representative') && !additionalInput) {
        console.log('its erroring')
        const errorString = runType === 'single_parcel' ? 'Parcel ID' : 'Rep ID';
        setError((prev) => ({
          ...prev,
          additionalInput: `Enter a valid ${errorString}`,
        }));
        return;
      }

      setIsFetching(true)

      // construct an object for all params
      setExpandedAccordion(accordionId);

      const params = {
        runType,  // all_clients, court_date, single_parcel, representative
        propertyType, // ex: residential, commercial, industrial
        reportType, // sales_analysis, assessment_analysis, marketing_analysis
        county: locationParams.county, // 
        municipality: locationParams.municipality,
        village: locationParams.village,
        TaxYear: parseInt(locationParams.TaxYear),
        MuniCode: locationParams.MuniCode,
        courtDate,
        // Have this be conditional, 
        singlePid: additionalInput, // this needs to be renamed for the appropriate flag.
      };
      // console.log
      // Here, update it so that you start a loading state in the lower level MUI table.
      // once the first response comes back, update value so the user goes into the proper view.

      // HERE IS WHERE YOU SHOULD UPDATE TO THE NEW FLOW. Just call KNN from right here.
      // ^^^^^^^^^^^
      
      // NOW - you run the different calls (the load client IDS call, then KNN - have this also create)
      // set a state which percolates down into your "run" datgrid to show the "History" of this run
      // Then, run your different fetch functions
      // Then, drop the user into the summary table (can just do the home component for rn until post refactor)
      const firstResult = { id: 1, name: locationParams.municipalityString, value: 'Example Value' };
      setDataGridRows([firstResult]);
      setCompSheet([])
      setOptimizedComps([])
      
      // this initialization kicks off the whole stream, awaiting is probably wrong.
      const response = await handleInitializeFetch(params);
    },
    // [runType, user.userAccess.level, locationParams.county]
    [runType, locationParams, courtDate, additionalInput, propertyType, reportType]
  );

  // Here begins the retarded old logic:
  // refactored the initialization, removed adjustment load.
  async function loadClientIds(muniObj) {
        console.log('obj going into load client ids',muniObj)
        try{
        const loadClientsPromise = await axiosInstance.post(`/load_client_ids`, muniObj);
        // Process clients once they arrive
        let clientResponseArr = loadClientsPromise.data;
        const clientList = clientResponseArr[0];
        const negotiationCases = clientResponseArr[1];
        return [clientList,negotiationCases]; // Return updated object or clients as needed
      }
      catch(err){
        console.log(err)
        toast.error('Error loading clients', err)
        // error = err;
        setIsFetching(false)
        return [];
      }
  }

  // use this for the manual review false saving logic, otherwise rest should disappear.
  // the streaming update to this function removed the helper function entirely. TBD if that is desired behavior or not.
  const handleInitializeFetch = async (params, manualOverride = false) => {
    const {MuniCode, village, TaxYear, courtDate, runType, singlePid} = params;
    // here, 
    // here, set the sarchparams to have comp =1
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has('comp') || searchParams.get('comp') !== '1') {
      searchParams.set('comp', '1');
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }

    console.log('initialization params',params)
    let formattedCourtDate = null;
    // format the courtdate (confirm this is still necessary)
    if(courtDate){
      formattedCourtDate = courtDate
        ? new Date(new Date(courtDate).getTime() - new Date(courtDate).getTimezoneOffset() * 60000)
              .toISOString()
              .split('T')[0]
        : '';
        console.log('formatted court date',formattedCourtDate)
    }

    // reset your compref
    globalCompRef.current = {
        properties: [],
        cases: [],
        totalCases: 0,
    };

    // need to revisit this once settings are done - have it update these the user context level.
    // Need to load these in from the localstorage if they exist here.
    let updateNegotiationObj = {
        MuniCode,
        TaxYear,
        ...(formattedCourtDate ? { CourtDate: formattedCourtDate, CourtRun: 1 } : { CourtRun: 0 }),
        showSettledCases: showSettledCases, // abstract this to user level settings (stored local for now)
        manualReview: manualReview, // Same ^
        manualReviewOverride: manualReviewOverride, // ^
        scarOnly: scarOnly, // ^
        reviewedCasesFlag: reviewedCasesFlag, // ^
        RepId: repID, // reimplement the rep run
    };

    // this is wholly rendundant fromt he above i think
    if (courtDate === '' || courtDate === null) {
        delete updateNegotiationObj.CourtDate;
    }

    try {
        // If you put in a single specific PID to run ()
        let updatedCases = []; // i think this is no longer relevant
        let negotiationCases = [];
        if (singlePid) {
            console.log('Running a single PID');
            console.log(singlePid)
            // updateNegotiationObj.cases = [singlePid];
            negotiationCases = [singlePid];
            updateNegotiationObj.MuniCode = singlePid.slice(0, 3); // Update MuniCode from PID
            updateNegotiationObj.overrideIds = [singlePid]; // Update overrideIds from PID
        } 
        // else {
            // Generic fetch for all cases
            console.log('pre load ids negoitiation obj',updateNegotiationObj)
            const clientIdResponse = await loadClientIds(updateNegotiationObj);
            // Temporary get adjustments
            const loadAdjustmentsResponse = await axiosInstance.post(`/load_adjustments`, updateNegotiationObj);
            let adjustments = null;
            const adjustmentResponse = loadAdjustmentsResponse.data;
            adjustments = adjustmentResponse.response;
            console.log('client id response',clientIdResponse)
            // if(!singlePid){
            updatedCases = clientIdResponse[0];
            negotiationCases = clientIdResponse[1];
            console.log('updated cases',negotiationCases)
            // }
            if (updatedCases.length === 0) {
                console.log('No client IDs found, aborting fetch.');
                // reimplement this with your new settings
                toast.error(`No ${showSettledCases ? '' : 'unsettled'} clients found in this area.`);
                return;
            }
            // if it can this should happen after

            // NEED TO REDO THIS FLOW:
            if (!manualReview) {
                console.log('Entering the non-manual save function.');
                const userObject = JSON.parse(localStorage.getItem('userInfo'));
                console.log(userObject)

                const saveObject = {
                    ManualReviewFlag: manualReview,
                    ManualOverride: manualReviewOverride,
                    Cases: updatedCases,
                    UserId: userObject.userId || null,
                    VillageFlag: village !== 'All' ? 1 : 0,
                    TaxYear: parseInt(TaxYear),
                    RepId: repID,
                };

                // REVISIT this whole thing
                saveObject.Cases = saveObject.Cases.map((item, index) => {
                    const compsObject = globalCompRef.current.properties[1][index].parcel_id
                        .slice(1, 6)
                        .reduce((acc, value, index) => {
                            acc[index + 1] = value;
                            return acc;
                        }, {});

                    const newCaseItem = {
                        Subject: item,
                        Comps: compsObject,
                        ...(Array.isArray(globalCompRef.current.properties[0][index]?.RepID) &&
                        globalCompRef.current.properties[0][index].RepID[0] !== ''
                            ? { RepId: globalCompRef.current.properties[0][index].RepID[0] }
                            : { RepId: null }),
                    };
                    return newCaseItem;
                });

                const savingComps = await saveComps(saveObject);
                console.log(savingComps);
                // Here, see about dropping user into review page?
            }
        // }
        const updatedNegotiationObj = { ...updateNegotiationObj, uids: updatedCases, cases: negotiationCases };
        console.log('Updated negotiation object:', updatedNegotiationObj);
        
        // here set your negotiation object -- no need to load settlements anymore
        // updatedNegotiationObj.cases = negotiationCases;
        setNegotiationObj(updatedNegotiationObj);
        setAdjustments(adjustments);
        setReviewPage(true);
        setReviewId(1)
    } catch (error) {
        console.error('An error occurred:', error);
        toast.error('An error occurred during the fetch. Please try again.', {
            position: 'top-right',
            autoClose: 3000,
        });
    } finally {
      console.log('KNN query is finished')
        // setIsFetching(false);
    }
  };

  const handleRunAnyway = () => {
    setConfirmationOpen(false);
    runAnalysis({accordionId:'case', force:true}) // analysis, and force it to run.
  };

  const handleConfirmationClose = () => setConfirmationOpen(false);
  

  // Need to update this to return the muni string form this backend route to complete this flow fully.
  const handleSetCourtDateOptions = async (courtDate) => {
    setCourtDate(courtDate);
    // here, set the courtdate options state
    const response = await axiosInstance.post(`/load_court_munis`, { CourtDate: courtDate });
    // IF the length of the munis is more than one, then open the dialogue. otherwise, just set the value of the muni / county / year.
    setCourtDateOptions(response.data);

    // if multiple courtdates scheduled, have the user pick which to run.
    if(response.data.length > 1){
    setOpenDialog('courtDateSelection');
    }else{
      // this doesn't set the municipality string, how do we do that? ,municipalityString:response.data[0].MuniName
      // Update muni, county, taxyear.
      updateLocationParams({municipality:response.data[0].muni.slice(1,3),county:response.data[0].muni.slice(0,1),TaxYear:response.data[0].TaxYear, MuniCode:response.data[0].muni})
    }
  }

  const handleAdditionalInputChange = useCallback((input) => {
    setAdditionalInput(input);
    // here, set the county and Muni
    if (input?.length > 5) {
      const county = input.slice(0, 1);
      const municipality = input.slice(1, 3);
      const muniCode = input.slice(0, 3);


      // Perform lookups, have to define dynamically so not stale refs.
      const countyString = countyLookup[county] || 'Unknown County';
      const municipalityLookup = Object.fromEntries(getMunicipalities(countyLookup[county]).map(({ key, selectVal }) => [selectVal, key]))
      const municipalityString = municipalityLookup[municipality] || 'Unknown Municipality';
      
      const searchParams = new URLSearchParams(window.location.search);

      // Update the search params with the new values
      const updates = { county, municipality, MuniCode: muniCode, countyString, municipalityString };
      Object.entries(updates).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    
      // Reflect the changes in the URL
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      
      updateLocationParams({
          municipality,
          county,
          MuniCode: muniCode,
          countyString: countyString,
          municipalityString: municipalityString,
      });
  } 

  // const { getCounties, getMunicipalities, getVillages } = useNYData();
    // updateLocationParams({municipality:response.data[0].muni.slice(1,3),county:response.data[0].muni.slice(0,1),TaxYear:response.data[0].TaxYear, MuniCode:response.data[0].muni})
  }, [updateLocationParams, countyLookup, municipalityLookup, municipalities]);

  // Define the width (max) of the thing you are passing in.
  // Need to make court date selection smaller.
  const fields = [
    {
      type: 'select',
      label: 'Property Type',
      value: propertyType,
      width: '140px',
      onChange: (event) => setPropertyType(event.target.value),
      options: [
        { label: 'Residential', value: 'residential' },
        { label: 'Commercial', value: 'commercial', disabled: true },
        { label: 'Industrial', value: 'industrial', disabled: true },
      ],
    },
    {
      type: 'select',
      label: 'Report',
      value: reportType,
      onChange: (event) => setReportType(event.target.value),
      options: [
        { label: 'Sales Analysis', value: 'sales_analysis' },
        { label: 'Assessment Analysis', value: 'assessment_analysis', disabled: true },
        { label: 'Marketing Analysis', value: 'marketing_analysis', disabled: true },
      ],
    },
    {
      type: 'select',
      label: 'Run Type',
      value: runType,
      onChange: handleRunTypeChange,
      width: '140px',
      options: [
        { label: 'All Clients', value: 'all_clients' },
        { label: 'Court Date', value: 'court_date', disabled: user.userAccess.level !== 'aventine' },
        { label: 'Single Parcel', value: 'single_parcel', disabled: user.userAccess.level !== 'aventine' },
        { label: 'Representative', value: 'representative', disabled: true },
      ],
    },
    ...(runType === 'court_date'
      ? [
          {
            type: 'select',
            label: 'Court Date',
            value: courtDate,
            onChange: (event) => {
              if (error.courtDate) {
                setError((prev) => ({ ...prev, courtDate: null })); // Reset courtDate error
              }
              handleSetCourtDateOptions(event.target.value);
            },
            options: courtDates,
            error: error.courtDate,
            width: '140px',
          },
        ]
      : []),
    ...(runType === 'single_parcel' || runType === 'representative' || runType === 'all_clients'
      ? [
          {
            type: 'text',
            label: runType === 'single_parcel' ? 'Parcel ID' : 'Rep ID',
            value: additionalInput,
            width: '140px',
            visible: runType === 'all_clients' ? false : true,
            error: error.additionalInput,
            onChange: (event) => {
              if (error.additionalInput) {
                setError((prev) => ({ ...prev, additionalInput: null })); // Reset additionalInput error
              }
              // Need it here to set the county and Muni
              handleAdditionalInputChange(event.target.value);
              // setAdditionalInput(event.target.value);
            },
          },
        ]
      : []),
  ];

  const analysisButtons = [
    {
      type: 'icon',
      tooltip: 'Advanced Settings',
      onClick: () => handleOpenDialog('settings'),
    },
    {
      type: 'button',
      variant: 'contained',
      color: 'primary',
      label: 'Run',
      onClick: () => runAnalysis({ accordionId: 'case' }), // define which one to be opening
      disabled: getIsFetching, // Disable button when fetching
      endIcon: getIsFetching ? <CircularProgress size={16} sx={{
        color: 'white', // Force white color
      }}/> : null, // Show progress icon when fetching
    },
  ];

  // make sure you keep a stable ref for this & memoize the rows
  const dataGridProps = useMemo(
    () => ({
      columns: [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          renderCell: (params) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center', // Vertically center content
                gap: 1,
                height: '100%', // Ensures proper alignment in the cell
                overflow: 'hidden', // Prevents overflow issues
              }}
            >
              <Typography
                sx={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // here, call the 
                  // setReviewId(params.id)
                  setReviewPage(true);
                  setReviewId(1)
                }
                }
              >
                {params.value}
              </Typography>
              {getIsFetching && <CircularProgress size={16} />}
            </Box>
          ),
        },
        { field: 'value', headerName: 'Value', width: 150 },
      ],
      rows: dataGridRows,
      localeText: { noRowsLabel: 'Run Analysis to see results' },
      pageSizeOptions: [5, 10, 20],
      disableColumnMenu: true,
      disableSelectionOnClick: true,
    }),
    [getIsFetching, dataGridRows]
  );

  // Programatic navigation to the next summary table screen.
  if (reviewId !== null && getIsFetching) {
    // Render Navigate to redirect when reviewId is set
    const searchParams = window.location.search; // Get current search params
    return <Navigate to={`/analysis/${reviewId}${searchParams}`} />;
  }

  return  (
    <div className='w-full h-full flex flex-col'>
    <Container
      maxWidth="xl" // Options: 'xs', 'sm', 'md', 'lg', 'xl' or false for no max width
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        py: 2,
      }}
    >

      {/* The muni-multiselect (sets params) */}
      <MuniSelectionV2
        error={error}
        handleClearError={clearError}
        locationParams={locationParams}
        updateLocationParams={updateLocationParams}
        // defaultTaxYear={defaultTaxYear}
      />
      
      {/* move these cards to their own component */}
      {/* top display cards (most important stats for area): */}
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        {['Card 1', 'Card 2', 'Card 3'].map((title, index) => (
          <Box
            key={index}
            sx={{
              flex: 1,
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              boxShadow: 1,
              borderRadius: 1,
              p: 2,
            }}
          >
            <Typography variant="h6">{title}</Typography>
          </Box>
        ))}
      </Box> */}
    
      {/* Case Analysis container (finally, lol) */}
      <AnalysisAccordion
        title="Case Analysis"
        fields={fields}
        buttons={analysisButtons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'case'}
        onExpandToggle={() =>
          setExpandedAccordion(expandedAccordion === 'case' ? null : 'case')
        }
      >
      </AnalysisAccordion>

      {/* Equity Analysis container - UNUSED */}
      <AnalysisAccordion
        title="Equity Analysis"
        fields={[]}
        buttons={analysisButtons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'equity'}
        disabled={true}
        onExpandToggle={() =>
          // setExpandedAccordion(false)
          console.log('not ready yet')
        }
        tooltip='Coming Soon!'
      >
      </AnalysisAccordion>

      {/* Big run warning: */}
      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Large Query Warning</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This is a large query and may take some time to complete. Are you sure you want to
            proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose}>Cancel</Button>
          <Button onClick={handleRunAnyway} variant="contained" color="primary">
            Run Anyway
          </Button>
        </DialogActions>
      </Dialog>

      {/* Settlements analysis placeholder */}
      {/* <AnalysisAccordion
        title="Settlement Analysis"
        fields={[]}
        buttons={buttons}
        dataGridProps={dataGridProps}
        expanded={expandedAccordion === 'settlement'}
        onExpandToggle={() =>
          setExpandedAccordion(expandedAccordion === 'settlement' ? null : 'settlement')
        }
      /> */}
      <AdvancedSettings
        open={openDialog === 'settings'}
        onClose={handleCloseDialog}
      />

      {/* Court Municipality select Dialog */}
      <Dialog open={openDialog === 'courtDateSelection'} onClose={handleCloseDialog}>
        <DialogTitle>Municipality Selection</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Please select which municipality you would like to run.
          </Typography>
          <Select
            value={selectedCourtDate || ''}
            onChange={handleSelectionChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              Select a Municipality
            </MenuItem>
            {courtDateOptions.map((option, index) => (
              <MenuItem key={index} value={option.muni}>
                {option.muni}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button
            onClick={() => {
              console.log('Run with selected option:', selectedCourtDate);
              handleCloseDialog();
              runAnalysis({accordionId:'case'})
            }}
            variant="contained"
            color="primary"
            disabled={!selectedCourtDate}
          >
            Run
          </Button>
        </DialogActions>
      </Dialog>
  </Container>
  </div>
  );
}

CaseAnalysisContainer.whyDidYouRender = true;
export default CaseAnalysisContainer;
