import { useEffect } from "react";
import {useAdminSettingsStore} from './store';
import demoPath from '../images/demo/Demo.mp4'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';


function Placeholder({}){
const apiKey = process.env.REACT_APP_FORM_API_KEY; // put this top level if it works?

	function submitForm(e){
		e.preventDefault();
		const form = document.getElementById('form');
		const result = document.getElementById('result');
		const formData = new FormData(form);
		const topText = document.getElementById('submitText')
		const button = document.getElementById('submitBut')

		var object = {};
		formData.forEach((value, key) => {
			object[key] = value
		});
		var json = JSON.stringify(object);
		button.innerHTML = "Please wait..."
		fetch('https://api.web3forms.com/submit', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				},
				body: json
			})
			.then(async (response) => {
				let json = await response.json();
				if (response.status === 200) {
					// Reduce the top margin
					form.classList.toggle('hidden')
					topText.classList.add('hidden')
					result.classList.remove('hidden')
					// Maybe break out fantastic to be bold?
					result.innerHTML = 'Thanks for reaching out, our team will be in touch shortly!'
					// result.classList.add('font-bold')
				} 
				else {
					console.log(response);
					result.classList.remove('hidden')
					result.innerHTML = json.message;
					result.classList.add('text-danger');
				}
			})
			.catch(error => {
				console.log(error);
				result.classList.remove('hidden')
				result.innerHTML = "Something went wrong!";
			})
			.then(function() {
				form.reset();
				// if you want to include something after a period of time, can throw this funct back in.
				// setTimeout(() => {
				// 	result.innerHTML = "Go Back"; 
				// 	result.classList.add('text-white');
				// }, 5000);
			});
	}


    // Dark mode demo if in dark mode
    // Light mode demo if in light mode
    // Disable the menu option
    // Disable search
    // Provide a tooltip for 'hold your horses!'

	// create a top level state variable (in storage)
	// if route === / then set it to true, use this to disable all the stuff you want to.


	// Hamburger menu onclick == disabled when at / route.
	// Onclick set the tooltip "hold your horses!"
	// 
    return(
		<Box sx={{ p: 6, bgcolor: "background.default", color: "text.primary" }} className='w-full h-full  bg-white'>
		<Container>
		  <Grid container spacing={4} alignItems="center" justifyContent="center">
			{/* Form Section */}
			<Grid item xs={12} lg={6}>
			  <Box
				sx={{
				  p: 4,
				  borderRadius: 2,
				  bgcolor: "background.paper",
				  boxShadow: 3,
				  textAlign: "center",
				}}
			  >
				<Typography variant="subtitle1" color="primary" fontWeight="bold" gutterBottom>
				  New age property valuation
				</Typography>
				<Typography variant="h2" fontWeight="bold" gutterBottom>
				  Propriety
				</Typography>
				<Typography variant="body2" paragraph id="submitText">
				  Precision property valuation. Built with ensemble Machine Learning models, enhanced by AI data validation.
				</Typography>
				
				<Box sx={{ display: "none", mt: 4 }} id="result">
				  Fantastic. Our team will be in touch shortly!
				</Box>
				<form noValidate action="https://api.web3forms.com/submit" method="POST" onSubmit={submitForm} id="form">
				  <input type="hidden" name="access_key" value={apiKey} />
				  
				  <TextField
					id="name"
					name="name"
					label="Your name"
					variant="outlined"
					required
					fullWidth
					margin="normal"
					placeholder="Your name"
					sx={{
						mt: 1, // Adjust the top margin
						mb: 1, // Adjust the bottom margin
					  }}
					  InputProps={{
						sx: {
						  padding: "0px 0px", // Adjust the padding (Y: 4px, X: 8px as an example)
						},
					  }}
				  />
				  <TextField
					id="email"
					name="email"
					type="email"
					label="Email address"
					variant="outlined"
					required
					fullWidth
					margin="normal"
					placeholder="Email address"
				  />
				  <Button
					type="submit"
					variant="contained"
					color="primary"
					fullWidth
					sx={{ mt: 2 }}
					id="submitBut"
				  >
					Contact us
				  </Button>
				</form>
			  </Box>
			</Grid>
	
			{/* Video Section */}
			<Grid item xs={12} lg={6}>
			  <Box
				component="video"
				controls
				loop
				autoPlay
				muted
				sx={{
				  width: "100%",
				  borderRadius: 2,
				  height: '100%',
				  boxShadow: 3,
				  objectFit: "cover",
				}}
			  >
				<source src={demoPath} type="video/mp4" />
				Your browser does not support the video tag.
			  </Box>
			</Grid>
		  </Grid>
		</Container>
	  </Box>
	);
} export default Placeholder