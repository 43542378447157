import { createContext, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const StableRouterContext = createContext(null);

const StableRouterContextProvider = ({ children }) => {
    const navigate = useNavigate();
    // const location = useLocation();
    const navigateRef = useRef(navigate);
    // const locationRef = useRef(location);

    return (
        <StableRouterContext.Provider value={{ navigateRef }}>
            {children}
        </StableRouterContext.Provider>
    );
};

const useStableNavigate = () => {
    const context = useContext(StableRouterContext);
    if (!context || !context.navigateRef || !context.navigateRef.current) {
        throw new Error('StableRouter context is not initialized for navigate');
    }
    return context.navigateRef.current;
};

// const useStableLocation = () => {
//     const context = useContext(StableRouterContext);
//     if (!context || !context.locationRef || !context.locationRef.current) {
//         throw new Error('StableRouter context is not initialized for location');
//     }
//     return context.locationRef.current;
// };

export {
    StableRouterContext,
    StableRouterContextProvider,
    useStableNavigate,
    // useStableLocation,
};
