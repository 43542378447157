import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DataGridContainerV2 from './DataGridContainerV2';

const AnalysisAccordion = ({
  title,
  fields,
  buttons,
  dataGridProps,
  expanded,
  onExpandToggle,
  disabled = false,
  tooltip = '',
}) => {

  
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300, // Set max height for the dropdown
        overflow: 'auto', // Enable scrolling
      },
    },
  };

  return (
    <Accordion
      expanded={!disabled && expanded}
      onChange={!disabled ? onExpandToggle : undefined}
      sx={{
        boxShadow: 1,
        borderRadius: 1,
        bgcolor: 'background.paper',
        '&:hover': {
          cursor: disabled ? 'not-allowed' : 'pointer',
          // bgcolor: disabled ? 'rgba(0, 0, 0, 0.1)' : 'background.paper',
        },
        '&.Mui-expanded': { bgcolor: 'background.paper', marginTop: '0px', marginBottom: '0px' },
        '&:focus-within': {
              bgcolor: 'background.paper', // Optional, depending on your design
            },
            '&.Mui-focused': {
              bgcolor: 'transparent', // Prevent gray background on accordion focus
            },
      }}
    >
      <Tooltip
        title={disabled ? tooltip : ''}
        placement="bottom"
        arrow
        className={`${disabled?'cursor-not-allowed':''}`}
        disableInteractive
      >
        {/* instead you should put a overlay div here if disabled to prevent clicks on the button. */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${title.replace(' ', '-').toLowerCase()}-content`}
          id={`${title.replace(' ', '-').toLowerCase()}-header`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            opacity: disabled ? 0.5 : 1,
            paddingY: 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
            backgroundColor: 'transparent !important', // Force transparent background
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
            '&.Mui-focused': {
              backgroundColor: 'transparent !important', // Prevent gray on focus
            },
            '&:focus-within': {
              backgroundColor: 'transparent !important', // Prevent gray when a child is focused
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              flex: '0 0 auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {title}
          </Typography>

          {/* Input Fields */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              flex: 1,
              // ml: 24, // update this so this only happens ons creeens MD (or lg?) and above. Smaller breakpoint should hide teh buttons
              // And rende rthem outside the summary.
            }}
          >

            {/* Update this so it can take a custom component input for compsearch (or just do as a one off - keep it outlined tho) */}
            {fields.map((field, index) => (
              <FormControl
                key={index}
                size="small"
                sx={{
                  flex: 1,
                  maxWidth: field.width||'160px',
                  visibility: field.visible === false ? 'hidden' : 'visible',
                  position: 'relative',
                }}
                error={!!field.error} // Display error if field.error is truthy
              >
                {field.type === 'select' ? (
                  <>
                    <InputLabel
                      sx={{ bgcolor: 'white !important', zIndex: '1 !important' }}
                    >
                      {field.label}
                    </InputLabel>
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      MenuProps={menuProps}
                      onClick={(e) => e.stopPropagation()} // Prevent accordion expand
                      onFocus={(e) => e.stopPropagation()} // Prevent focus event for background change
                    >
                      {field.options.map((option, i) => (
                        <MenuItem key={i} value={option.value} disabled={option.disabled}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <TextField
                    size="small"
                    label={field.label}
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    onClick={(e) => e.stopPropagation()} // Prevent accordion expand
                    error={!!field.error} // Error styling for the TextField
                  />
                )}
                {field.error && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{
                      position: 'absolute',
                      bottom: '-20px', // Adjust as needed to position error text below the input
                      left: '50%', // Align to the center of the parent
                      transform: 'translateX(-50%)', // Adjust alignment for exact centering
                      textAlign: 'center', // Ensure text is centered
                      whiteSpace: 'nowrap', // Prevent wrapping
                    }}
                  >
                    {field.error} {/* Display the error text */}
                  </Typography>
                )}
              </FormControl>
            ))}
          </Box>

          {/* Buttons */}
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', alignItems: 'center' }} >
            {buttons.map((button, index) =>
              button.type === 'icon' ? (
                <Tooltip title={disabled ? '' : button.tooltip} key={index} disableInteractive>
                  {/* <span style={{ display: 'inline-block' }}> */}
                    <IconButton
                      color="default"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!disabled) button.onClick();
                      }}
                      sx={{
                        borderRadius: '4px',
                        p: 0.5, // Reduced padding
                        '&:focus': { outline: 'none' }, // Remove outline
                        pointerEvents: disabled ? 'none' : 'auto', // Disable clicks when disabled
                      }}
                      // disabled={disabled} // Add visual disabled state
                    >
                      <SettingsOutlinedIcon />
                    </IconButton>
                  {/* </span> */}
                </Tooltip>
              ) : (
                <Button
                  key={index}
                  variant={button.variant}
                  color={button.color}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disabled) button.onClick();
                  }}
                  disabled={button.disabled} // Disable button when disabled
                  endIcon={button.endIcon || null} // Render endIcon if it exists
                >
                  {button.label}
                </Button>
              )
            )}
          </Box>
        </AccordionSummary>
      </Tooltip>

      {/* Accordion Details */}
      {!disabled && (
        <AccordionDetails>
          <Box
            sx={{
              height: 250,
              width: '100%',
              bgcolor: 'background.default',
            }}
          >
            {/* <DataGridPro {...dataGridProps} /> */}
            <DataGridContainerV2 {...dataGridProps} />
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default AnalysisAccordion;
