import React, { useState, useEffect, useCallback, useMemo, useRef, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useCompStore, usePersistedDataStore } from '../store.js';
import DownloadButton from './DownloadButton.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import SummaryCards from './SummaryCards';
import Typography from '@mui/material/Typography';

const SummaryCaseReviewTable = React.lazy(() => import('./SummaryCaseReviewTable'));

// This route calls get sales data every time you re-render it
// You should 

// Right now, negotiationCases is just the negotiation object.
//  do you want to constrain this to just the cases?
function ReviewSettlements({ handleUpdateStateCallback, updatedArray, negotiationCases, filterType, setFilterType, filteredNegotiationCases}) {
  
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  // pass in cases
  // const negotiationCases = getNegotiationObj.cases;
  // const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  // const [doneUpdating, setDoneUpdating] = useState(false);
  // const getCompSheet = usePersistedDataStore((state) => state.compSheet)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const municipalityString = queryParams.get('municipalityString') || 'All';
  // const hasFetchedSales = useRef(false); // Track if sales data has been fetched

  const totalIFMV = useMemo(() => {
    if(!negotiationCases) return 0;
    return negotiationCases.reduce((acc, item) => {
      if (item && item.IFMV) {
        return acc + item.IFMV;
      }
      return acc;
    }, 0);
  }, []);
  
  // Parsed out from the update function
    // Function to fetch sales data
    // const fetchSalesData = useCallback(async () => {
    //   if (hasFetchedSales.current) return; // Skip if already fetched
    //   console.log('fetching sales data');
    //   try {
    //     console.log(negotiationCases)
    //     const casesToMap = negotiationCases;
    //     const uids = casesToMap.map((item) => item.PID);
  
    //     const salesResponse = await axiosInstance.post('/get_sales_data', {
    //       TaxYear: taxYear,
    //       MuniCode: MuniCode,
    //       uids: uids,
    //     });
  
    //     const salesData = salesResponse.data;
  
    //     // Integrate the sales data into negotiation cases
    //     const updatedCases = casesToMap.map((caseItem) => {
    //       const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
    //       return saleInfo
    //         ? { ...caseItem, ...saleInfo }
    //         : caseItem;
    //     });
  
    //     setNegotiationObj({
    //       ...getNegotiationObj, // Spread other properties to retain them
    //       cases: updatedCases,   // Replace the cases key with updated values
    //     });
    //     hasFetchedSales.current = true; // Mark sales data as fetched
    //     toast.success('Sales data updated successfully');
    //   } catch (error) {
    //     console.error(error);
    //     toast.error('Error fetching sales data');
    //   }
    // }, [negotiationCases, taxYear, MuniCode]);

    // Summary data for Haverstraw
  const summaryData = {
    totalPropertyValue: 3855738615,
    totalPropertyTax: 36558951,
    annualTrend: 0.04545,
    propertiesGrieved: Math.round(totalIFMV),
  }

    // let runningSetlement = 0; // local var to tally your settlement value.
    // let runningIFMV = 0; // var to tally your IFMV's (as cases are settled to calc the % reduct)
    // let runningNC = 0;
  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
  const [selectionModel, setSelectionModel] = React.useState([]);

    // For the download
    const keys = ['parcel_id', 'Address', 'PropertyTown', 'PropertyZip', 'ClientName', 'SCARIndexNumber','PropertyAssessment', 'IFMV', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'Taxes', 'SubjectMarketValue', 'OfferValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'CaseNotes', 'RepID', 'settled'];
    
    const handleCellClick = useCallback((selection) => {
      console.log("Row ID:", selection);
      setSelectionModel(selection);
    }, [selectionModel]);

    return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
<div className="h-full flex flex-col">
  
  {/* Summary Stats Section */}
  {/* <div className='mt-12'> */}
  {/* <SummaryCards summaryData={summaryData} /> */}
  <Typography variant="h5" align="center" gutterBottom>
        {municipalityString} Summary
  </Typography>

  <div className="flex flex-col flex-grow h-0">
    {/* Filtering and Download Div */}
    <div className="flex justify-between mb-1 mt-2 flex-shrink-0">
      <div className="flex">
        <FormControl>
          <InputLabel sx={{ zIndex: '1 !important', background: 'transparent' }}>
            Case Filter
          </InputLabel>
          <Select
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                py: 1,
              },
              '& .MuiInputBase-input': {
                py: 1,
              },
            }}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filter Type"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="unreviewed">Unreviewed</MenuItem>
            <MenuItem value="open">Unsettled</MenuItem>
            <MenuItem value="scar">SCAR Filed</MenuItem>
            <MenuItem value="settled">Settled</MenuItem>
          </Select>
        </FormControl>
      </div>
      <DownloadButton
        caseData={negotiationCases}
        fileName={fileName}
        selectedRows={selectionModel}
        keys={keys}
      />
    </div>

    {/* Case Review Table */}
    
    <Suspense fallback={<CircularProgress />}>
          <SummaryCaseReviewTable
          updatedArray={updatedArray}
            handleCellClick={handleCellClick}
            selectionModel={selectionModel}
            filteredNegotiationCases={negotiationCases}
            negotiationCases={negotiationCases}
            handleUpdateStateCallback={handleUpdateStateCallback}
            // doneUpdating={getIsFetching}
          />
        </Suspense>
  </div>
  
</div>
  );
} export default ReviewSettlements
