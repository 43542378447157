import axiosInstance from '../axiosConfig'
import {useCallback } from "react";
import {toast} from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

export const handleCopy = (text, type) => {
  // here also include the address, town, ny, zip in the copy if address.
  navigator.clipboard.writeText(text)
    .then(() => {
      toast.success(`Copied ${type} to clipboard!`, {
        position: 'top-right',
        autoClose: 2000,
      });
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
};

export function getDefaultYear() {
  const today = new Date();
  return today.getMonth() >= 3
    ? today.getFullYear()
    : today.getFullYear() - 1;
}

// will this break if attempted from a diff IP address bc tryign to access this S3 bucket?
export async function GetCompPhoto(pid) {
  // console.log('running getCompPhoto')
  // console.log(pid)
  // Set the default photo URL
  let photo = null;
  try {
    // Fetch the photo using the provided PID
    const response = await fetch("https://aventine-photos.s3.amazonaws.com/" + pid + ".jpg");
    
    // Check if the photo exists
    if (response.status === 200) {
      photo = "https://aventine-photos.s3.amazonaws.com/" + pid + ".jpg";
    }
  } catch (error) {
    console.log(error);
  }

  // Return the photo URL
  return photo; // Return as an object with the PID as the key
}

export async function loadCourtDates() {
  try {
    const courtResponse = await axiosInstance.get('/load_court_dates');
    const courtDates = Object.keys(courtResponse.data);
    // courtDates.unshift(''); // Add an empty string at the start if necessary
    return courtDates.map((date) => ({
      label: date ? new Date(date).toLocaleDateString() : '', // Handle blank string for label
      value: date,
    }));
  } catch (error) {
    console.error('Error fetching court dates:', error);
    return [];
  }
}

// Absolutely insanely gross code. But this works for now. Hopefully you can just hire some poor soul who will fix this garbage
// at some point in the future. (My personal apologies if you are the one reading this - James)
export async function updateMuniObj({updateField, updateValue, updateString = '', navigate, urlString, courtMuni, nyDataObj, county = null, TaxYear=null, setCountyOptions}) {
const url = new URL(urlString, window.location.origin);
const searchParams = new URLSearchParams(url.search);
const currentValue = searchParams.get(updateField);
const courtDate = searchParams.get('CourtDate') // instead of removing from inputs (for laziness's sake just overwrote here)
county = searchParams.get('county') // instead of removing from inputs (for laziness's sake just overwrote here)
// Ensure that when running this for a court date, you also update the taxyear based on that specific courtdate.
// Requries passing in the taxYear to the function.
if (currentValue === updateValue) {
    return;
}
// Update the relevant search parameter
searchParams.set(updateField, updateValue);

if (updateField === 'county' || updateField ==='municipality' || updateField === 'village') {
    // This is where you set your munistring
    if (updateField === 'county') {
      searchParams.set('municipality', 'All');
      searchParams.set('village', 'All');
      searchParams.set('municipalityString', '');
      searchParams.set('villageString', '');
      searchParams.set('MuniCode', updateValue);
      searchParams.delete('municipality');
      // Find the key in nyDataObj that matches the selectVal with updateValue
      let countyString = '';
      for (const county in nyDataObj) {
          if (nyDataObj[county].selectVal === updateValue) {
              countyString = county; // Set countyString to the matching key
              break;
          }
      }
      // Set the countyString to the search parameters
      searchParams.set(updateField + 'String', countyString);
  } 
  else if (updateField === 'municipality') {
    const muniCode = county + updateValue;
    const countyString = searchParams.get('countyString');

    // If a courtDate is selected, update the tax year based on the courtDate's muni
    if (courtDate) {
        const courtMuniExists = courtMuni.find(court => court.muni === muniCode);
        if (courtMuniExists) {
            // Set the tax year to the matched value
            searchParams.set('TaxYear', courtMuniExists.TaxYear);
        }
    }

    // Find the key within the county's municipalities that matches updateValue
    let muniString = '';
    if (nyDataObj[countyString] && nyDataObj[countyString].municipalities) {
        for (const muniKey in nyDataObj[countyString].municipalities) {
            if (nyDataObj[countyString].municipalities[muniKey].selectVal === updateValue) {
                muniString = muniKey; // Set muniString to the matching key
                break;
            }
        }
    }

    // Set the MuniCode and county in search parameters
    searchParams.set('MuniCode', muniCode);
    searchParams.set('county', county);
    searchParams.set('municipalityString', muniString); // Set the extracted muniString
}else if (updateField === 'village') {
  const currentMuniCode = searchParams.get('MuniCode') || '';
  const newValues = updateValue.slice(-2); // Append these
  const countyString = searchParams.get('countyString');
  const muniString = searchParams.get('municipalityString');
  // the normal village handler:
  // Determine the length of the current MuniCode
  const currentLength = currentMuniCode.length;
  
  // Form the new MuniCode
  let updatedMuniCode;
  if (currentLength > 3) {
      updatedMuniCode = currentMuniCode.slice(0, -2) + newValues;
  } else {
      updatedMuniCode = currentMuniCode + newValues;
  }
  
  // Update the searchParams with the new MuniCode
  searchParams.set('MuniCode', updatedMuniCode);
  
  // Find the matching villageString within municipalities
  let villageString = '';
  if (nyDataObj[countyString] && nyDataObj[countyString].municipalities[muniString]) {
      const villageObj = nyDataObj[countyString].municipalities[muniString].villages;
      
      for (const villageKey in villageObj) {
          if (villageObj[villageKey].selectVal === updateValue) {
              villageString = villageKey; // Set villageString to the matching key
              break;
          }
      }
  }
  
  // Update searchParams with the extracted villageString
  searchParams.set('villageString', villageString);
}
} else if (updateField === 'CourtDate') {
        searchParams.delete('municipality')
        searchParams.delete('county')
        searchParams.delete('taxYear')
        if(updateValue === ''){ // set the default countyoptions if you remove the court date.
          setCountyOptions(
            [ // setting this here instead of @ higher level context for the moment.
              { value: "All", label: "All", selectVal: 'All'},
              ...Object.keys(nyDataObj).map(key => ({
                  label: key,
                  value: nyDataObj[key].selectVal
              }))
          ]
          )
        }
}

// Handler to clear out state if all selected
if (updateValue === 'All') {
  if (updateField === 'village') {
      searchParams.set('villageString', '');
      searchParams.set('village', 'All');
      searchParams.set('MuniCode', county + searchParams.get('municipality'));
  } else if (updateField === 'municipality') {
      searchParams.set('municipalityString', '');
      searchParams.set('municipality', 'All');
      searchParams.set('MuniCode', county);
  }
}

const newUrlString = `${url.pathname}?${searchParams.toString()}`;
navigate(newUrlString);
}


  export async function getCountyOptions({
      setMuniOptions,
      nyDataObj,
      courtMuni = [],
      navigate,
      setCountyOptions
  }) {
    // maybe break this out so you memoize the county options for the court date, and the muniOptions based on the court date you select
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
      
      // IF courtdate has a value, set your county options based on that. Then, based on county selection, set your available muni options.
      const countyOptions = [];  
      // its not clear to me that we need this if statement.
              courtMuni.forEach((court) => { // this properly iterates over this array to define the munioptions list.
                  let countyStringKey = court.muni.charAt(0);
                  
                  const courtCountyKey = Object.keys(nyDataObj).find(key => nyDataObj[key].selectVal === countyStringKey);
                  // Look for the countyStringKey in the countyOptions array of objects
                  const countyObjectExists = countyOptions.find(option => option.value === countyStringKey);
                  
                  if (!countyObjectExists) {
                    // console.log(`Adding ${countyStringKey} to countyOptions`);
                    const countyObject = {
                      value: countyStringKey,
                      label: courtCountyKey
                    };
                    countyOptions.push(countyObject);
                  }
  
              })
              
              if(countyOptions.length<2){ // if only one county avail, set. If multiple, force user to select.
                // console.log(countyOptions.length) // this should happen only if the user has multiple county to pick from.
                searchParams.set('county', countyOptions[0].value);
              }else{
                searchParams.set('county', 'select');
              }
            searchParams.set('municipality', 'All'); // force user to make a muni selection
            searchParams.set('MuniCode', '');
        if(courtMuni.length<2){ // when only one option, it sets the values as it should.
          const countyStringKey = courtMuni[0].muni.charAt(0);
            searchParams.set('MuniCode', courtMuni[0].muni);
            searchParams.set('TaxYear', courtMuni[0].TaxYear);
            searchParams.set('county', countyStringKey);
            searchParams.set('municipality', courtMuni[0].muni.substr(1, 2));
            // Set your munioptions here so it can update the muni options based on the court date.
            const matchingOptions = [];
            const courtCountyKey = Object.keys(nyDataObj).find(key => nyDataObj[key].selectVal === countyStringKey);
            
            // Lookup in the nyDataObject for the municipality based on the county + muni selection
            Object.keys(nyDataObj[courtCountyKey].municipalities).forEach((key) => {
                const municipality = nyDataObj[courtCountyKey].municipalities[key];
                if (municipality.selectVal === courtMuni[0].muni.substr(1, 2)) {
                  matchingOptions.push({
                    value: municipality.selectVal,
                    label: key,
                    selectVal: municipality.nameWithSelectVal,
                    county: countyStringKey,
                    TaxYear: courtMuni[0].TaxYear,
                  });
                }
            });
            setMuniOptions(matchingOptions);
          }
            // Instead of navigating and setting state here, you could return all these values and set them in the parent component.
            const newUrlString = `${url.pathname}?${searchParams.toString()}`;
            // console.log(countyOptions)
            setCountyOptions(countyOptions);
            navigate(newUrlString);
  }


  export async function getMunicipalityOptions({
    setMuniOptions,
    nyDataObj,
    county,
    courtMuni = [],
    taxYear,
    navigate,
  }) {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  // console.log(`county: ${county}`)
  // console.log(`taxYear: ${taxYear}`)
  // console.log(courtMuni)

  // Set your default options
  let countyKey = Object.keys(nyDataObj).find(key => nyDataObj[key].selectVal === county);
  if(county === 'All' || county === 'select'){
    return;
  }

    let muniOptions = [
      { value: "All", label: "All", selectVal: 'All'},
      ...Object.keys(nyDataObj[countyKey].municipalities).map((key) => ({
          value: nyDataObj[countyKey].municipalities[key].selectVal,
          label: key,
          selectVal: nyDataObj[countyKey].municipalities[key].nameWithSelectVal,
          TaxYear: taxYear
      }))
    ]
  if(courtMuni.length>1){ // when a court date run, set your options to only be what is scheduled, and set county drop.
            const matchingOptions = [];
            // IF courtdate has a value, set your county options based on that. Then, based on county selection, set your available muni options.
              courtMuni.forEach((court) => { // this properly iterates over this array to define the munioptions list.
                // If courtMuniCounty key is equal to the 'county' value, continue iterating. Otherwise, skip.
                if (court.muni.charAt(0) !== county) {
                  return;
                }
                  const muniSubstring = court.muni.substring(1, 3);
                  const countyStringKey = court.muni.charAt(0);
                  const courtCountyKey = Object.keys(nyDataObj).find(key => nyDataObj[key].selectVal === countyStringKey);
                  Object.keys(nyDataObj[courtCountyKey].municipalities).forEach((key) => {
                      const municipality = nyDataObj[courtCountyKey].municipalities[key];
                      if (municipality.selectVal === muniSubstring) {
                          matchingOptions.push({
                              value: municipality.selectVal,
                              label: key,
                              selectVal: municipality.nameWithSelectVal,
                              county: countyStringKey,
                              TaxYear: court.TaxYear,
                          });
                      }
                  });
              })
              muniOptions = matchingOptions
          }

          // If you only have one thing, set the value. otherwise tell user to pick a muni.
              if(muniOptions.length===1){
                // console.log('only one thing!')
                searchParams.set('municipality', muniOptions[0].value);
                searchParams.set('MuniCode', muniOptions[0].county+muniOptions[0].value);
                searchParams.set('TaxYear', muniOptions[0].TaxYear);
                searchParams.set('county', muniOptions[0].county);
              }else{ // otherwise drive the user to select a muni.
                if(courtMuni.length>1){
                searchParams.set('municipality', 'select');
                }
              }
              // You are deleting the village param -- this is why it defaults to all. Fine for now until you clean this entire dumpsterfire up
              searchParams.delete('village');
            const newUrlString = `${url.pathname}?${searchParams.toString()}`;
              // Sort the muniOptions array ascending by municipality value
              muniOptions.sort((a, b) => a.value - b.value);
              setMuniOptions(muniOptions)
              navigate(newUrlString);
}

  export function getVillageOptions(setVillageOptions, nyDataObj, municipality, county) {
    if(Object.keys(nyDataObj).length >0){
        
        if (municipality !== 'All') {
            const countyKey = Object.keys(nyDataObj).find(key => nyDataObj[key].selectVal === county);
            const muniKey = Object.keys(nyDataObj[countyKey].municipalities).find(key => nyDataObj[countyKey].municipalities[key].selectVal === municipality);
            const villages = nyDataObj[countyKey]?.municipalities[muniKey]?.villages || null;
            if (villages) {
                setVillageOptions(
                    [
                        { value: "All", label: "All", selectVal: 'All' },
                        ...Object.keys(villages).map((key) => ({
                            value: villages[key].selectVal,
                            label: key,
                            selectVal: villages[key].nameWithSelectVal
                        }))
                    ]
                );
            } else {
                setVillageOptions([{ value: "All", label: "All", selectVal: 'All' }]);
            }
        }else{
          setVillageOptions([{ value: "All", label: "All", selectVal: 'All' }]);
        }
    }
  }

const useReviewedComps = (comp, getCourtComps, taxYear, getCompSheet, setCourtComps) => {
  const getReviewedComps = useCallback(async (courtComps) => { // this needs to handle the "null's"
    // console.log('running helperfunction to get court comp data + inventory');
    try {
      // Construct compObject with subject and comps
      const compObject = {
        subject: courtComps.PID,
        comp1: courtComps.Comps[1],
        comp2: courtComps.Comps[2],
        comp3: courtComps.Comps[3],
        comp4: courtComps.Comps[4],
        comp5: courtComps.Comps[5],
      };
      // console.log(compObject);

      const parcelIds = getCompSheet[comp]?.parcel_id || [];
      const compSheetIndexes = Object.values(compObject).map(compValue => {
        if (Number.isInteger(compValue)) {
          return null;
        }
        const index = parcelIds.indexOf(compValue);
        return index !== -1 ? index : null;
      });
      // console.log(compSheetIndexes)

      let reviewedCompInventory = [];
      try {
        const reviewedCompInventoryResponse = await axiosInstance.post(`/load_comparables`, compObject);
        reviewedCompInventory = reviewedCompInventoryResponse.data;
        // console.log(reviewedCompInventory)

        // Initialize the object for the current 'comp' index from getCourtComps or as an empty object
        const initialObject = getCourtComps[comp] || {};

        const keys = Object.keys(getCompSheet[comp] || {});
        keys.forEach(key => {
          const valueArray = getCompSheet[comp][key];
          // console.log(valueArray)

          // Map over compSheetIndexes to construct the array of values for this key
          const values = compSheetIndexes.map((compSheetIndex,idx) => {
            if (compSheetIndex !== null) {
              return valueArray[compSheetIndex] || null;
            }
            // HERE you should set values per the reviewed comp inventory instead of null.

            // valueArray[compSheetIndex] = compObject[comp[idx]]
            return null;
          });

          // Assign the array of values to the corresponding key in initialObject
          if(key==='parcel_id'){
            // PUt in handling to render that form your compObj
          }
          initialObject[key] = values;

        });

        // Update the specific index in the array with initialObject
        const updatedCourtComps = [...getCourtComps];
        updatedCourtComps[comp] = initialObject;

        // console.log('Final Object:', updatedCourtComps);
        setCourtComps(updatedCourtComps);

      } catch (error) {
        console.error('Error fetching reviewedCompInventory:', error);
        return;
      }
      return reviewedCompInventory;

    } catch (error) {
      console.error('Error fetching additional comps:', error);
    }
  }, [comp, getCourtComps, getCompSheet, setCourtComps]);

  return getReviewedComps;
};

export default useReviewedComps;
