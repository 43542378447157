import React, { memo, useMemo } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

// haven't done anything wiht this yet.
const DataGridContainerV2 = ({ columns, rows, pageSizeOptions = [5, 10, 20], localeText }) => {
  // Memoize columns to ensure they remain stable
  const memoizedColumns = useMemo(() => columns, [columns]);
  console.log('container re-rendering')

  // const memoizedColumns = useMemo(() => {
  //   return columns.map((col) => ({
  //     ...col,
  //     renderCell: col.renderCell
  //       ? (params) => <CustomCellRenderer value={params.value} />
  //       : col.renderCell, // Add custom renderers only if provided
  //   }));
  // }, [columns]);

  return (
    <DataGridPro
      columns={memoizedColumns}
      rows={rows}
      pageSizeOptions={pageSizeOptions}
      localeText={localeText || { noRowsLabel: 'Run Analysis to see results' }}
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
    />
  );
};

// Memoize the entire component to prevent unnecessary renders
export default memo(DataGridContainerV2);
