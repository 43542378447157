import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import {useUser} from './UserContext'

// need to expand this, so that we have seperate delineations for user with graph access, other levels, etc.
const PrivateRoute = ({ element, fallbackPath = '/signin', requiredAccessLevel }) => {
  // Replace this with actual session-based authentication logic
  const { user, loading } = useUser();
  
  // Can update this to your context user object.
  const hasAccess = () => {
    if (!user || !user.userAccess) return false;

    if (requiredAccessLevel) {
      // this lets us specifically instance parts of FE based on user's access.
      return user.userAccess.level === requiredAccessLevel;
    }

    return true;
  };

  return hasAccess() ? element : <Navigate to={fallbackPath} />;
};

export default PrivateRoute;