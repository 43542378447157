import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
  Typography,
  Slider,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const FIELD_TYPES = {
  TOGGLE: 'toggle',
  MULTICHECK: 'multicheck',
  SLIDER: 'slider',
};

function AdvancedSettings({ open, onClose }) {
  const [activeTab, setActiveTab] = useState(0);
  const [settings, setSettings] = useState({
    manualReview: true,
    defaultRunTypes: ['settled'],
    dateRange: [null, null],
    squareFeetFilter: 40,
    salePriceFilter: 40,
    acreageFilter: 40,
  });

  const handleFieldChange = (name, value) => {
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  const renderSalesAnalysisTab = () => (
    <>
      <Typography variant="h6" sx={{ mb: 0 }}>Sales Analysis Settings</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        The selections you make will be saved as your default options.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormControlLabel
        className="items-center"
        control={
            <Switch
            checked={settings.manualReview}
            onChange={(e) => handleFieldChange('manualReview', e.target.checked)}
            sx={{
                pointerEvents: 'auto', // Ensure the toggle itself is clickable
            }}
            />
        }
        label="Enable Manual Review"
        sx={{
            alignItems: 'flex-start',
            pointerEvents: 'none', // Disable container-level clicks
            '& .MuiSwitch-root': {
            pointerEvents: 'auto', // Ensure the Switch is independently clickable
            },
            '& .MuiFormControlLabel-label': {
            pointerEvents: 'auto', // Ensure the label text is independently clickable
            },
        }}
        />

        <FormControl component="fieldset" alignItems='center'>
          <FormLabel className=''>Select Default Run Types</FormLabel>
          <FormGroup row>
            {[
              { value: 'scarFiled', label: 'Show SCAR Filed Only' },
              { value: 'settled', label: 'Show Settled Only' },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={settings.defaultRunTypes.includes(option.value)}
                    onChange={(e) => {
                      const newValues = e.target.checked
                        ? [...settings.defaultRunTypes, option.value]
                        : settings.defaultRunTypes.filter((val) => val !== option.value);
                      handleFieldChange('defaultRunTypes', newValues);
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DatePicker
              label="Earliest Sale Date"
              value={settings.dateRange[0]}
              onChange={(newDate) => handleFieldChange('dateRange', [newDate, settings.dateRange[1]])}
              renderInput={(props) => <TextField {...props} size="small" />}
            />
            <DatePicker
              label="Last Sale Date Cutoff"
              value={settings.dateRange[1]}
              onChange={(newDate) => handleFieldChange('dateRange', [settings.dateRange[0], newDate])}
              renderInput={(props) => <TextField {...props} size="small" />}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Hard Filter Features</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Do not show comps that are x% less than subjects:
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        <FormControl sx={{ flex: '1 1 45%' }}>
          <FormLabel>Square Feet Filter ({settings.squareFeetFilter}%)</FormLabel>
          <Slider
            value={settings.squareFeetFilter}
            onChange={(e, value) => handleFieldChange('squareFeetFilter', value)}
            valueLabelDisplay="auto"
            min={0}
            disabled
            max={100}
          />
        </FormControl>
        <FormControl sx={{ flex: '1 1 45%' }}>
          <FormLabel>Acreage Filter ({settings.acreageFilter}%)</FormLabel>
          <Slider
            value={settings.acreageFilter}
            onChange={(e, value) => handleFieldChange('acreageFilter', value)}
            valueLabelDisplay="auto"
            disabled
            min={0}
            max={100}
          />
        </FormControl>
        <FormControl sx={{ flex: '1 1 45%' }}>
          <FormLabel>Sale Price Filter ({settings.salePriceFilter}%)</FormLabel>
          <Slider
            value={settings.salePriceFilter}
            onChange={(e, value) => handleFieldChange('salePriceFilter', value)}
            valueLabelDisplay="auto"
            disabled
            min={0}
            max={100}
          />
        </FormControl>
      </Box>
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Advanced Settings</DialogTitle>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        sx={{
            '& .MuiTab-root': {
            opacity: 1, // Ensure non-disabled tabs are fully visible
            },
            '& .MuiTab-root.Mui-disabled': {
            backgroundColor: 'transparent !important', // Remove background for disabled tabs
            color: 'gray !important', // Set text color to gray
            },
        }}
        >
            <Tab label="Sales Analysis" />
            <Tab label="Equity Analysis" disabled />
            <Tab label="Marketing" disabled />
            <Tab label="Model Weights" disabled />
        </Tabs>

      <DialogContent>
        {activeTab === 0 && renderSalesAnalysisTab()}
        {activeTab !== 0 && <Typography variant="body2">This tab is under construction.</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            console.log('Settings Saved:', settings);
            onClose();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdvancedSettings;