import React, { useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { Magic } from "magic-sdk"; // Import Magic SDK
import { NavLink, useNavigate } from "react-router-dom";
// import { useAuth } from "../AuthContextProvider";
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import { createUserInfo, isUserAuthorized } from '../UserContext';
import { useUser } from '../UserContext'; // Decoupled user data

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(1),
  margin: "auto",
  maxWidth: "450px",
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginBottom: "10vh",
  position: "relative",
}));


const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY); // Initialize Magic SDK
const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID
const clientSecret = process.env.REACT_APP_GOOGLE_API_CLIENT_SECRET
const redirectUri = window.location.origin+process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI

export default function SignIn() {
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const [signingIn, setSigningIn] = useState(!!code);
  const emailRef = useRef();
  const [signinError, setSigninError] = useState(false);
  const navigate = useNavigate();

  // Need to update thi
  // const {user, login} = useAuth();
  const {login} = useUser();


  // GOOGLE SIGNIN STUFF:
  useEffect(() => {
    if (signingIn) {
      setSigningIn(false);
    }
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      setSigningIn(true);
      exchangeCodeForToken(code)
        .then(data => {
          if (data.error) {
            throw new Error(data.error); // Handle token exchange error
          }
          const accessToken = data.access_token;
          return fetchUserInfo(accessToken); // Fetch user info with access token
        })
        .then(userInfo => {
          if (!isUserAuthorized(userInfo.email)) {
            throw new Error('Unauthorized email domain');
          }
          
          // Use createUserInfo to construct the user object
          const completeUserInfo = createUserInfo(userInfo.email);
          login(completeUserInfo);
          navigate('/analysis'); // Navigate to the table page
          console.log('Navigating to sign user in.');
        })
        .catch(error => {
          console.error('Error:', error);
          if (error.message === 'Unauthorized email domain') {
            setEmailError(true);
            setEmailErrorMessage("This email is not authorized. Contact sales for access.");
          }
          setSigningIn(false);
        });
    }
  }, []);

  const fetchUserInfo = async (accessToken) => {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const userInfo = await response.json();
    return userInfo;
  };

  const exchangeCodeForToken = async (authorizationCode) => {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ // move these to env variable.
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri, // Same as the redirect URI used in authorization request
        grant_type: 'authorization_code',
      }),
    });
    const data = await response.json();
    return data;
  };

  const handleGoogleRedirectSignIn = () => { 
    const scope = 'openid profile email';
    
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${encodeURIComponent(scope)}`;
    window.location.href = googleAuthUrl;
  //  setUser(true)
  };

  // MAGIC LINK STUFF:
  const handleMagicLinkSubmit = async (event) => {
    event.preventDefault();
    const email = emailRef.current.value;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      return;
    }

    if (!isUserAuthorized(email)) {
      setEmailError(true);
      setEmailErrorMessage("This email is not authorized. Contact sales for access.");
      return;
    }

    setEmailError(false);
    setEmailErrorMessage("");
    setSigningIn(true);

    try {
      const did = await magic.auth.loginWithEmailOTP({ email });
      // console.log(`DID Token: ${did}`);
      
      // Use createUserInfo to construct the user object
      const userInfo = createUserInfo(email);
      login(userInfo);
      navigate('/analysis'); // Navigate to the table page
    } catch (error) {
      console.error('Magic link login error:', error);
      setEmailError(true);
      setEmailErrorMessage("Failed to send magic link. Please try again.");
      setSigningIn(false);
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between" className="flex flex-col w-full h-full items-center">
        <Card variant="outlined">
          {signingIn ? (
            <Typography variant="h4" className="text-center">
              Signing in...
              <CircularProgress />
            </Typography>
          ) : (
            <>
              <Typography variant="h4" className="text-center">
                Sign in
              </Typography>
              <Typography variant="body2" className="text-center">
                Welcome, please sign in to continue
              </Typography>
              <Box
                component="form"
                onSubmit={handleMagicLinkSubmit}
                noValidate
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <TextField
                    error={emailError}
                    helperText={emailErrorMessage}
                    id="email"
                    type="email"
                    onChange={() => {
                      setEmailError(false);
                      setEmailErrorMessage("");
                    }}
                    placeholder="your@email.com"
                    required
                    fullWidth
                    inputRef={emailRef}
                  />
                </FormControl>
                <Button type="submit" variant="contained">
                  Sign in with Email
                </Button>
              </Box>
              <Divider sx={{ mt: 1 }}>OR</Divider>
              <Button
              type="submit"
              fullWidth
              variant="outlined"
              onClick={() => handleGoogleRedirectSignIn()}
              startIcon={<GoogleIcon />}
              // disabled={signingIn}
            >
              Sign in with Google
            </Button>

            <NavLink to="/" variant="body2" align="center" className={'text-blue-500 underline'} sx={{ mt: 1 }}>
              Contact sales for access
            </NavLink>
            </>
          )}
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
}
