import React, { useEffect, useState, useRef, useCallback } from 'react';
import axiosInstance from '../../axiosConfig.js'
import { useLocalDataStore, usePersistedDataStore, } from '../store.js';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import _, {isEqual} from 'lodash';
import dayjs from 'dayjs';
import GraphParams from './GraphParams.js'
import GraphContainer from './GraphContainer.js'
import Container from '@mui/material/Container';
// TODO Change axis 1 to be X axis
// Axis 2 to be Y axis

// Microload and setmicro are coming down, don't do that.

function Visualizations({nyDataObj, yearDropdownOptions}){
    // You should refactorr this, so that the state for the dropdowns is managed within each dropdown component.
    // console.log('rerender viz')
    const [numGraphs, setNumGraphs] = useState(1)
    const [activeGraph, setActiveGraph] = useState(1)


    // Update so that the graph params are rendered WITHIN the plotly graph component
    // and 

return(
    // <div className='flex flex-col flex-grow h-full p-2'>
    <Container
      maxWidth="xl" // Options: 'xs', 'sm', 'md', 'lg', 'xl' or false for no max width
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: 1,
        p: 2,
        overflow: 'auto !important',
      }}
    >

    {/* Graph Visualizations */}
    {numGraphs <= 1? // actually 1
    <div className='justify-center h-full flex-grow'>
        <div className='content-center h-full text-center w-full bg-white rounded-md'>
        
        {/* Our modular plotly graph component */}
            <GraphContainer
            nyDataObj={nyDataObj}
            yearDropdownOptions={yearDropdownOptions}
            />
        </div>
    </div>
    :
    numGraphs === 2 ?
    <div className='m-auto grid grid-cols-2 gap-4 px-12 justify-center'>
        {/* Isolate graph in its own component probably once you have the inputs to pass in */}
        <div className={`m-auto content-center text-center h-[80vh] w-full bg-white rounded-md ${activeGraph === 1 ? 'border-primary border-2' : 'hover:cursor-pointer'}`}
        onClick={()=>setActiveGraph(1)}>
        Graphic display
        </div>
        <div className={`m-auto content-center text-center h-[80vh] w-full bg-white rounded-md ${activeGraph === 2 ? 'border-primary border-2' : 'hover:cursor-pointer'}`}
        onClick={()=>setActiveGraph(2)}>
        Graphic display
        </div>
    </div>
    :
    numGraphs === 3 ?
    <>
    <div className='h-[80vh] flex justify-between gap-4 px-12'>
        <div className=' w-1/2 bg-white rounded-md'>
        Graphic display
        </div>
        <div className='flex flex-col w-1/2 gap-4'>
        <div className='h-[40vh] bg-white rounded-md '>
            Graphic display
        </div>
        <div className='h-[40vh] bg-white rounded-md'>
            Graphic display
        </div>
        </div>
    </div>
    </>
    :
    numGraphs > 3?
        <>
        <div className='h-[80vh] flex justify-between gap-4 px-12'>
            <div className='flex flex-col w-1/2 gap-4'>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            </div>
            <div className='flex flex-col w-1/2 gap-4'>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            <div className='h-1/2 bg-white rounded-md'>
                Graphic display
            </div>
            </div>
        </div>
        </>
    :
    null
    }
    {/* </div> */}
    {/* </div> */}
    </Container>


    )
} export default React.memo(Visualizations)
