import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

function CompSearch({ handleClose, compSelectCallback, searchData, placeholderText = "PID or Address" }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === "") {
      setSuggestions([]);
      setLoading(false);
      setDebouncedTerm("");
    } else {
      setLoading(true);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length > 0) {
        setDebouncedTerm(searchTerm);
      } else {
        setSuggestions([]);
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedTerm) {
        setLoading(true);

        const filteredData =
          searchData?.filter((item) => {
            if (typeof item === "string") {
              return item.toLowerCase().includes(debouncedTerm.toLowerCase());
            } else if (typeof item === "object") {
              return (
                (item.PID && item.PID.toLowerCase().includes(debouncedTerm.toLowerCase())) ||
                (item.Address && item.Address.toLowerCase().includes(debouncedTerm.toLowerCase()))
              );
            }
            return false;
          }) || [];

        setSuggestions(filteredData);
        setLoading(false);
      }
    };
    fetchSuggestions();
  }, [debouncedTerm, searchData]);

  return (
    <div className="relative w-full">
      <TextField
        placeholder={placeholderText}
        variant="standard"
        fullWidth
        value={searchTerm}
        onChange={handleInputChange}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <div style={{ width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {loading && <CircularProgress size={20} />}
              </div>
            </InputAdornment>
          ),
        }}
      />
      {suggestions.length > 0 && (
        <ul
          className="absolute left-0 right-0 bg-white border shadow-md p-1 rounded-md mt-1 max-h-60 overflow-y-auto"
          style={{ zIndex: 9999 }}
        >
          {suggestions.map((item, index) => {
            const suggestionText = typeof item === "string" ? item : item.Address || item.PID;
            return (
              <li
                key={index}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => {
                  compSelectCallback(item);
                  setSuggestions([]);
                  setSearchTerm("");
                  setDebouncedTerm("");
                }}
              >
                {suggestionText}
              </li>
            );
          })}
        </ul>
      )}
      {debouncedTerm && suggestions.length === 0 && (
        <ul
          className="absolute left-0 right-0 bg-white border shadow-md p-1 rounded-md mt-1"
          style={{ zIndex: 9999 }}
        >
          <li className="px-4 py-2 text-center">No matching addresses</li>
        </ul>
      )}
    </div>
  );
}

export default CompSearch;
